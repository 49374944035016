import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { DashboardServiceService } from 'src/app/Service/dashboard-service.service';
import { UserRegistrationService } from 'src/app/Service/user-registration.service';
import { WalkinItem } from 'src/app/models/walkincenter';
import Chart from 'chart.js/auto';
import * as crypto from 'crypto-js';

@Component({
  selector: 'app-current-bill',
  templateUrl: './current-bill.component.html',
  styleUrls: ['./current-bill.component.css']
})
export class CurrentBillComponent {

 
   title = 'ng-chart';
  chart: any = [];
   chart1: any = [];
 data: any[] = []

  billData: any = []
  public qrdata_tc: any = null;
  public qrdata_upi: any = null;
  public qrdata_paytm: any = null;
  date!: Date;
  mobile = '';
  email = '';
  dueAmt = '';
  openingCycle = '';
  closeCycle = '';
  dueDate = '';
  str5000 = '';
  important1 = '';
  previoseReading = '';
  assessedActual = '';
  previousDate = '';
  closingReading = '';
  closingActual = '';
  closingDate = '';
  billPeriod = '';
  details = '';
  flat = '';
  landmark = '';
  city = '';
  meterImgTxt = '';
  meterImg = '';
  historyDate1 = '';
  historyDate2 = '';
  historyDate3 = '';
  historyDate4 = '';
  historyDate5 = '';
  historyDate6 = '';
  historyDate11 = '';
  historyDate22 = '';
  historyDate33 = '';
  historyDate44 = '';
  historyDate55 = '';
  historyDate66 = '';
  historyIMG1 = true;
  historyIMG2 = true;
  historyIMG3 = true;
  historyIMG4 = true;
  historyIMG5 = true;
  historyIMG6 = true;
  walkinCenter: WalkinItem[] = [];
  walkincenterData = '';
  isLoad = false;
  str_arrears_emi = 0;
  mtrImg = '';
  mtrImgTxt = '';
  imageSrcString: any;
  totalPartA = 0;
  otherCharges = 0;
  v_creditbalance = 0;
  v_SGST = 0;
  v_CGST = 0;
  vPartB = 0;
  abTotal = 0;

  firstMessageFlag=false;
  secondMessageFlag=false;
   importantNew = '';
    importantNew1 = '';

    mychart='';

    uptoTotal = 0;
    uptodueAmt: any;

  constructor(public datepipe: DatePipe, private service: DashboardServiceService, public DashboardService: DashboardServiceService, private UserService: UserRegistrationService) { }

  ngOnInit(): void {
    //this.mychart =this.mychart+"<div><canvas id='canvas1'>"+this.chart1+"</canvas></div>";
    
    // this.loadChart1()
    this.UserService.checkLogin()
    // this.qrdata_tc = "https://chart.googleapis.com/chart?chs=180x180&cht=qr&chl=" + encodeURIComponent("https://www.mahanagargas.com/assets/images/pdf/_regitration-form-terms-and-condition_0d27e650ee.pdf") + "&choe=UTF-8";
     this.qrdata_tc = "https://image-charts.com/chart?chs=180x180&cht=qr&choe=UTF-8&chl=" + encodeURIComponent("https://www.mahanagargas.com/assets/images/pdf/_regitration-form-terms-and-condition_0d27e650ee.pdf");
    let data = JSON.parse(JSON.stringify(sessionStorage.getItem('UserCredential')))

    this.service.getWalkinCenter().subscribe(response => {
      this.walkinCenter = JSON.parse(JSON.stringify(response)).details
    })

    if (data) {
   //    var ciphertext = crypto.AES.encrypt(JSON.parse(data).CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
   // this.service.getBillingHistoryInfoNew({ CAno: ciphertext }).subscribe(response => {
   //   console.log('dslkfjsdlfjkdsjflkd',response)
   // })
 
      this.data = JSON.parse(data).CaNo
     // console.log({ CAno: JSON.parse(data).CaNo })
      var txtMobile = JSON.parse(data).Mobile;
      var txtEmail = JSON.parse(data).Email;
      this.mobile = Array(6).join("*") + + txtMobile.slice(-4);
      this.email = txtEmail.slice(0, 2) + Array(8).join("*") + txtEmail.slice(-9);
        var ciphertext = crypto.AES.encrypt(JSON.parse(data).CaNo, 'BGpdmQCacHxMne6uI8Gnec7IRAKuPGjr').toString();
      this.service.getBillingHistoryInfoNew({ CAno: ciphertext}).subscribe(response => {
      // this.service.getCurrentBill({ CAno: JSON.parse(data).CaNo }).subscribe(response => {
        let data = JSON.parse(JSON.stringify(response)).details
        //console.log(response)
        if (this.DashboardService.billno == undefined) {
          this.billData = data[0]
        } else {
          this.billData = data.filter((x: any) => {
            return x.bill_no == this.DashboardService.billno
          })
          this.billData = this.billData[0]
        }
        if (this.billData.MR_Note == "99") {
          this.service.getBillImage({ date: this.billData.MR_Path }).subscribe(response => {
            let data = JSON.parse(JSON.stringify(response))
            if (data.message) {
              this.meterImg = 'https://www.mahanagargas.com:3000' + data.message;
             // console.log('meter img',this.meterImg);
              this.isLoad = true;
               this.mtrImg = `<td width="51%" align="center" valign="top" style="font-size:9px;" >Meter Image : <br><img src="${this.meterImg}" width="120px" height="70px"></td>`
              // this.mtrImg = `<img src="${this.meterImg}" width="100">`
            }
          })
        }else {
          this.isLoad = true;
        }

        //this.isLoad = true;


        //**********New Change********//

        // if(this.billData.EBILL=="Y" && this.billData.cnt_bill > "1"){
        //    this.importantNew = "Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill";
   
        //  // console.log("For existing customers (who have opted for paperless billing earlier ):“Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill”")
        //   this.firstMessageFlag=true;
        //    this.secondMessageFlag=false;
        // }else if (this.billData.EBILL=="Y" && this.billData.cnt_bill == "1"){
        //    this.importantNew1 = "We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill";
        //  // console.log("We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill")
        //   this.firstMessageFlag=false;
        //    this.secondMessageFlag=true;
        // }



        if(this.billData.EBILL=="Y" && this.billData.discount <= "-10"){
           this.importantNew = "Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill";
   
         // console.log("For existing customers (who have opted for paperless billing earlier ):“Thank you for opting paperless billing. By switching to paperless billing , you are reducing paper wastage & saving the environment .As a token of appreciation from our side , we are crediting ₹10 in your current bill”")
          this.firstMessageFlag=true;
           this.secondMessageFlag=false;
        }else if (this.billData.EBILL=="Y" && this.billData.discount == "-10"){
           this.importantNew1 = "We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill";
         // console.log("We at MGL, Prioritise customer satisfaction as well as Environment and thank you for opting paperless billing. By switching to paperless billing , you not only reduce paper waste but also ensure access to bill 24/7.small token of appreciation from our side , we are crediting ₹10 in your current bill")
          this.firstMessageFlag=false;
           this.secondMessageFlag=true;
        }
         

        //********New Change END*******//

        //this.billData = data[0]
       // console.log(this.billData)
        //this.walkincenterData = "Thane (West): 101 & 102, Landmark Arcade, Eastern Express Highway, Louiswadi, Thane-400604. Landmark: Near Nitin Company Junction Nearest Rail Head: Thane (Central Railway)"
        for (var i = 0; i < this.walkinCenter.length; i++) {
          if (this.walkinCenter[i].Portion == (this.billData.group).substring(0, 4)) {
            this.walkincenterData = this.walkinCenter[i].WalkinCenters
          }
        }
        if (this.billData.bill_date_history2) {
          this.historyIMG1 = true;
          // this.historyDate1 = this.getBillInfo(this.billData.bill_date_history2) + " To " + this.getBillInfo(this.billData.bill_date_history1)+" "+this.getBillInfoYear(this.billData.bill_date_history1);
          this.historyDate1 = this.getBillInfo(this.billData.bill_date_history2)+" "+this.getBillInfoYear(this.billData.bill_date_history2) + " to ";
          this.historyDate11 = this.getBillInfo(this.billData.bill_date_history1)+" "+this.getBillInfoYear(this.billData.bill_date_history1);
        } else {
          this.historyIMG1 = false;
        }
        if (this.billData.bill_date_history3) {
          this.historyIMG2 = true;
          this.historyDate2 = this.getBillInfo(this.billData.bill_date_history3)+" "+this.getBillInfoYear(this.billData.bill_date_history3) + " to ";
          this.historyDate22 = this.getBillInfo(this.billData.bill_date_history2)+" "+this.getBillInfoYear(this.billData.bill_date_history2);
        } else {
          this.historyIMG2 = false;
        }
        if (this.billData.bill_date_history4) {
          this.historyIMG3 = true;
          this.historyDate3 = this.getBillInfo(this.billData.bill_date_history4)+" "+this.getBillInfoYear(this.billData.bill_date_history4) + " to ";
          this.historyDate33 = this.getBillInfo(this.billData.bill_date_history3)+" "+this.getBillInfoYear(this.billData.bill_date_history3);
        } else {
          this.historyIMG3 = false;
        }
        if (this.billData.bill_date_history5) {
          this.historyIMG4 = true;
          this.historyDate4 = this.getBillInfo(this.billData.bill_date_history5)+" "+this.getBillInfoYear(this.billData.bill_date_history5) + " to ";
          this.historyDate44 = this.getBillInfo(this.billData.bill_date_history4)+" "+this.getBillInfoYear(this.billData.bill_date_history4);
        } else {
          this.historyIMG4 = false;
        }
        if (this.billData.bill_date_history6) {
          this.historyIMG5 = true;
          this.historyDate5 = this.getBillInfo(this.billData.bill_date_history6)+" "+this.getBillInfoYear(this.billData.bill_date_history6) + " to ";
          this.historyDate55 = this.getBillInfo(this.billData.bill_date_history5)+" "+this.getBillInfoYear(this.billData.bill_date_history5);
        } else {
          this.historyIMG5 = false;
        }
        if (this.billData.bill_date_history7) {
          this.historyIMG6 = true;
          this.historyDate6 = this.getBillInfo(this.billData.bill_date_history7)+" "+this.getBillInfoYear(this.billData.bill_date_history7) + " to ";
          this.historyDate66 = this.getBillInfo(this.billData.bill_date_history6)+" "+this.getBillInfoYear(this.billData.bill_date_history6);
        } else {
          this.historyIMG6 = false;
        }

        // console.log(this.historyDate1)
        // console.log(this.historyDate2)
        // console.log(this.historyDate3)
        // console.log(this.historyDate4)
        // console.log(this.historyDate5)
        // console.log(this.historyDate6)

        if (this.billData.PLOT_NO != null && this.billData.PLOT_NO != "") {
          this.details += "Plot : " + this.billData.PLOT_NO + ", ";
        }

        if (this.billData.flat_no != null && this.billData.flat_no != "") {
          this.details += "Flat No : " + this.billData.flat_no + ", ";
        }

        if (this.billData.floor != null && this.billData.floor != "") {
          this.details += "Floor No : " + this.billData.floor + ", ";
        }

        if (this.billData.wing != null && this.billData.wing != "") {
          this.details += "Wing : " + this.billData.wing;
        }

        if (this.billData.bldg_name != null && this.billData.bldg_name != "") {
          this.flat += this.billData.bldg_name + ", ";
        }
        if (this.billData.city2 != null && this.billData.city2 != "") {
          this.flat += this.billData.city2 + ", ";
        }
        if (this.billData.street != null && this.billData.street != "") {
          this.flat += this.billData.street + ", ";
        }
        if (this.billData.colony != null && this.billData.colony != "") {
          this.flat += this.billData.colony + ", ";
        }
        if (this.billData.road_name != null && this.billData.road_name != "") {
          this.flat += this.billData.road_name + ", ";
        }

        if (this.billData.land_mark != null && this.billData.land_mark != "") {
          this.landmark = "LandMark : " + this.billData.land_mark + ", ";
        }

        if (this.billData.location != null && this.billData.location != "") {
          this.city += this.billData.location + ", ";
        }
        if (this.billData.city != null && this.billData.city != "") {
          this.city += this.billData.city + ", ";
        }
        if (this.billData.pin != null && this.billData.pin != "") {
          this.city += " - " + this.billData.pin;
        }

        if (!isNaN(this.billData.amt_pybl)) {
          this.dueAmt = Number(this.billData.amt_pybl) + 100 + ".00";
          this.dueDate = this.billData.due_date;
        }
        if (this.billData.ASSET_SD) {
          // this.str5000 = 'YOUR INTEREST FREE, REFUNDABLE SECURITY DEPOSIT TOWARDS LAST MILE CONNECTIVITY WITH US AS IMG IS ₹ ' + this.billData.ASSET_SD
          this.str5000 = 'Your interest free, refundable security deposit towards last mile connectivity wiht us as img is ₹ ' + this.billData.ASSET_SD
        } else {
          this.str5000 = '';
        }

        if (this.billData.bill_date_history1) {
          this.billPeriod = this.getBillInfo(this.billData.bill_date_history1) + " TO " + this.billData.bill_date;
        }
        else {
          this.billPeriod = this.billData.metdtf + " TO " + this.billData.bill_date;
        }


         const strdy_msg5 = this.billData.dy_msg5;
        const credit = this.billData.credit;
        const arrtot = this.billData.arrtot;
 
          if (strdy_msg5) {
          if (strdy_msg5 != "0" && Number(arrtot) > 0) {
            this.important1 = this.important1 + "<p>We thank you for paying ₹" + strdy_msg5.replace("Thank you for your payment of Rs.", "") + "/- against the PNG bill dated " + this.billData.bill_date_history1 + " and request you to make balance payment of ₹ " + arrtot + "/- for settling the full bill amount and avoid imposition of delayed payment charges and disconnection.</p>";
          }
        }

        if (strdy_msg5 && credit) {
          if (strdy_msg5 != "0" && Number(arrtot) == 0 && Number(credit) == 0) {
            this.important1 = this.important1 + "<p>We thank you for making full payment of ₹" + strdy_msg5.replace("Thank you for your payment of Rs.", "") + "/- against the PNG bill dated " + this.billData.bill_date_history1 + "</p>";
          }
        }



      


        if (this.billData.EBILL=="") {
            this.important1 = this.important1 + "<p>Opt for paperless Invoice by sending SMS to 922555557  “No Hard Copy”</p>";
          }

          if (!txtMobile && !txtEmail) {
          this.important1 = this.important1 + "<p>For registering your Mobile number or E-mail id, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>";
        }
        // else if (txtMobile && !txtEmail) {
        //   this.important1 = this.important1 + "<p>For registering your E-mail id, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>";
        // }

        this.important1 = this.important1 + "<p>We value your Privacy. If you wish to avoid visit of Meter reader inside your premises, please provide us your meter reading or generate your bill by "  + this.billData.n_mr_date4 +", for this purpose you will receive an SMS from MGL with a link between "  + this.billData.n_mr_date3 +" to "+ this.billData.n_mr_date4 +".  </p>";

 // else if (!txtMobile && txtEmail) {
        //   this.important1 = this.important1 + "<p>For registering your Mobile number, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>";
        // }

        const billDate = new Date(this.billData.bill_date);
        const dtNewBillDisply = new Date("27/11/2021");
        const dtNewBillDisply_01042022 = new Date("01/04/2022");

        if (billDate >= dtNewBillDisply)  //new format after 26/11/2021 
        {
          if (billDate >= dtNewBillDisply_01042022) //new format after 31/03/2022
          {
            //strInfo = strInfo + "* w.e.f. 1st April 2022 Vat is applicable @3%";
          }
          else {
            this.important1 = this.important1 + "<p>* w.e.f. 27th November 2021 Single Slab tariff is applicable</p>";
          }
        }
        const strClosing = this.billData.closing;
        if (strClosing) {
          if (strClosing.length >= 3) {
            if (strClosing.substring(strClosing.length - 3) == "(A)") {
              this.important1 = this.important1 + "<p>We have raised the current bill based on the Assessment of the consumption derived from the last six bills</p>";
            }
            else if (strClosing.substring(strClosing.length - 3) == "(E)") {
              this.important1 = this.important1 + "<p>We have raised the current bill based on the Assessment of the consumption derived from the last six bills. Please note that we could not obtain the reading when our Meter Reader visited your premises on " + this.billData.MR_Date + "</p>";
            }

            if (strClosing.substring(strClosing.length - 3) != "(E)" && strClosing.substring(strClosing.length - 3) != "(A)" && (this.billData.MR_Note == "99" || this.billData.MR_Note == "M8" || this.billData.MR_Note == "M15" || this.billData.MR_Note == "M12" || this.billData.MR_Note == "M2" || this.billData.MR_Note == "M3" || this.billData.MR_Note == "M10")) {
              this.important1 = this.important1 + "<p>We have raised the current bill based on the Reading obtained by us through visit by ";
              this.important1 = this.important1 + (this.billData.MR_Note == "99" ? "<b><u>Meter Reader</u></b>" : "Meter Reader");
              this.important1 = this.important1 + "/" + ((this.billData.MR_Note == "M8" || this.billData.MR_Note == "M15") ? "<b><u>provided by you via SMS</u></b>" : "provided by you via SMS");
              this.important1 = this.important1 + "/" + (this.billData.MR_Note == "M10" ? "<b><u>MGL connect</u></b>" : "MGL connect");
              this.important1 = this.important1 + "/" + ((this.billData.MR_Note == "M12" || this.billData.MR_Note == "M2" || this.billData.MR_Note == "M3") ? "<b><u>Mailed to customer Support ID of MGL.</u></b>" : "Mailed to customer Support ID of MGL." + "</p>");
            }
          }
        }

        const strdiscount = this.billData.discount;
        const stramt_pybl = this.billData.amt_pybl;
        if (strdiscount) {
          if (stramt_pybl == "ACH Operative" && Number(strdiscount) == -50) {
            this.important1 = this.important1 + "<p>We have received your request for NACH and the same has been activated. As per the current scheme, a sum of ₹ " + (Number(strdiscount) * -1) + "/- is credited and is shown as part of this item.</p>";
          }

          //if (stramt_pybl == "ACH Operative" && (Number(strdiscount) < 0 && Number(strdiscount) > -50))
          if (stramt_pybl != "" && (Number(strdiscount) < 0 && Number(strdiscount) > -50)) {
            if(this.billData.cnt_bill == " " || this.billData.cnt_bill == null){
               this.important1 = this.important1 + "<p>1% discount Rs ₹ " + (Number(strdiscount) * -1) + "/- against your previous bill settled through NACH has been credited to your account and is shown here.</p>";
            }
           
          }
        }

        if(this.billData.bu_type=="DOM047" || this.billData.bu_type=="DOM036" || this.billData.bu_type=="DOM041" ){
          this.important1 = this.important1 + "<p>PNG KA MAHATSOV is credited in this bill for Rs 3,000/- valid for one year or till balance available whichever is earlier</p>";

        }



        var str_EMI_ARR = 0;
        var v_adjustaddarrears = 0;
        var v_adjustaddcredit = 0;
        this.v_creditbalance = 0;
        if (this.billData.EMI_ARR.length > 0) {
          str_EMI_ARR = Number(this.billData.EMI_ARR);
        }
        if (this.billData.adjust.length > 0 && Number(this.billData.adjust) > 0) {
          v_adjustaddarrears = Number(this.billData.adjust);
        }
        else {
          v_adjustaddcredit = Number(this.billData.adjust)
        }
        var v_consump_based_sd = this.billData.consump_based_sd ? Number(this.billData.consump_based_sd) : 0;
        this.str_arrears_emi = Number(this.billData.arrtot) + Number(this.billData.EMI_OPEN) + Number(v_consump_based_sd);
        //this.str_arrears_emi = Number(this.billData.EMI_OPEN);
        this.v_creditbalance = Number(this.billData.credit) + v_adjustaddcredit + Number(this.billData.discount);
        var v_emiopen = this.billData.EMI_OPEN ? Number(this.billData.EMI_OPEN) : 0;
        var v11 = this.billData.cons ? Number(this.billData.cons) : 0;
      //  console.log('cons',v11)
        var v12 = this.billData.vat ? Number(this.billData.vat) : 0;
       //  console.log('vat',v12)
        var v13 = this.billData.arrtot ? Number(this.billData.arrtot) + str_EMI_ARR + v_adjustaddarrears + v_emiopen : 0;
       // console.log('v13',v13)
        var v_cf = this.billData.cf ? Number(this.billData.cf) : 0;
        var v_bf = this.billData.bf ? Number(this.billData.bf) : 0;

        this.totalPartA = v11 + v12 + v13 + this.v_creditbalance;




        var v_Asset_500 = this.billData.ASSET_500 ? Number(this.billData.ASSET_500) : 0;;
        var v_othercharges = Number(this.billData.CU_Charges) + v_Asset_500;

       // console.log('v_othercharges',v_othercharges)

        var v_asset_based_sd = this.billData.asset_based_sd ? Number(this.billData.asset_based_sd) : 0;

        var v_int_inst = this.billData.int_inst ? Number(this.billData.int_inst) : 0;
        var v_NAME_CHRG = this.billData.NAME_CHRG ? Number(this.billData.NAME_CHRG) : 0;
        var v_FIXCED_CHARGE = this.billData.FIXCED_CHARGE ? Number(this.billData.FIXCED_CHARGE) : 0;
        var v_ebill_amt = this.billData.ebill_amt ? Number(this.billData.ebill_amt) : 0;

        var One_time_chrg = this.billData.One_time_chrg ? Number(this.billData.One_time_chrg) : 0;

        var dec_OtherCharges = Number(v_othercharges) + Number(v_asset_based_sd) + Number(v_consump_based_sd) + Number(v_int_inst) + Number(v_NAME_CHRG);
        this.otherCharges = dec_OtherCharges + Number(v_FIXCED_CHARGE) + Number(v_ebill_amt) + Number(One_time_chrg);
       
      //  console.log('this.otherCharges,10',this.otherCharges,10)

        var str_sgst = 0;
        var str_cgst = 0;
        var dec_sgst = 0;
        var dec_cgst = 0;

        str_sgst = this.billData.SGST ? Number(this.billData.SGST) : 0;
        str_cgst = this.billData.CGST ? Number(this.billData.CGST) : 0;
        var v_int_inst_sgst = this.billData.int_inst_sgst ? (this.billData.int_inst_sgst) : 0;
        var v_int_inst_cgst = this.billData.int_inst_cgst ? (this.billData.int_inst_cgst) : 0;

        var v_NAME_SGST = this.billData.NAME_SGST ? Number(this.billData.NAME_SGST) : 0;
        var v_NAME_CGST = this.billData.NAME_CGST ? Number(this.billData.NAME_CGST) : 0;

        var v_ZFSGST = this.billData.ZFSGST ? Number(this.billData.ZFSGST) : 0;
        var v_ZFCGST = this.billData.ZFCGST ? Number(this.billData.ZFCGST) : 0;

        var v_ebill_amt_cgst = this.billData.ebill_amt_cgst ? Number(this.billData.ebill_amt_cgst) : 0;
        var v_ebill_amt_sgst = this.billData.ebill_amt_sgst ? Number(this.billData.ebill_amt_sgst) : 0;

         var One_time_cgst = this.billData.One_time_cgst ? Number(this.billData.One_time_cgst) : 0;

           var One_time_sgst = this.billData.One_time_sgst ? Number(this.billData.One_time_sgst) : 0;

        // console.log("int_inst_sgst", v_int_inst_sgst);
        // console.log("NAME_SGST", v_NAME_SGST);
        // console.log("ZFSGST", v_ZFSGST);
        // console.log("ebill_amt_cgst", v_ebill_amt_cgst);

        dec_sgst = Number(str_sgst) + Number(v_int_inst_sgst) + Number(v_NAME_SGST) + Number(v_ZFSGST) + Number(v_ebill_amt_cgst) + Number(One_time_sgst);
        dec_cgst = Number(str_cgst) + Number(v_int_inst_cgst) + Number(v_NAME_CGST) + Number(v_ZFCGST) + Number(v_ebill_amt_sgst) + Number(One_time_cgst);
        this.v_SGST = Number(dec_sgst);
        this.v_CGST = Number(dec_cgst);
        var v_min_charges = this.billData.min_chg ? Number(this.billData.min_chg) : 0;
        // console.log("this.otherCharges", this.otherCharges)
        // console.log("v_min_charges", v_min_charges)
        // console.log("dec_sgst", dec_sgst)
        // console.log("dec_cgst", dec_cgst)
        this.vPartB = Number(this.otherCharges) + Number(v_min_charges) + Number(dec_sgst) + Number(dec_cgst);
        this.abTotal = this.totalPartA + this.vPartB;
        const strecslimit = this.billData.ecslimit;

        //   console.log(" this.vPartB",  this.vPartB)
        // console.log("this.totalPartA", this.totalPartA)
        //  console.log("this.abTotal", this.abTotal)



        if (v_FIXCED_CHARGE > 0) {
          this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>Fixed Charge of ₹" + v_FIXCED_CHARGE.toFixed(2) + "/-    is included in Other Charges.</b></p>";
        }

        // if (v_ebill_amt > 0) {
        //   this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>Convenience charges of ₹" + v_ebill_amt.toFixed(2) + "/- plus GST is included in Other Charges w.e.f. 01.08.2023.</b></p>";
        // }

        if (v_Asset_500 > 0) {
          this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>Other Charges includes Balance payment on account of Last Mile Connectivity Deposit.</b></p>";
        }

        //if (this.billData.EBILL != 'Y') {
        //  this.important1 = this.important1 + "<p style=\"font-weight:bold;\"><b>We invite you to join our \"Go Green Movement\" by opting for paperless PNG bill by sending an SMS \"No Hard Copy\" to 9223 55 5557. If you wish to receive paper Bill in future, the same shall be provided at ₹10/bill.</b></p>";
        //}
        if (strecslimit) {
          //if (stramt_pybl.toUpperCase() == "ACH OPERATIVE" && (vPartA + vPartB) > Number(strecslimit) && EMI_ARR == "0")
          if (this.billData.ACH_Flg == "N" && (this.totalPartA + this.vPartB) > Number(strecslimit)) {
            var nachAmt= this.totalPartA + this.vPartB;
           // console.log(nachAmt)
             var nachAmt1=((this.totalPartA + this.vPartB) - Number(strecslimit))
            this.important1 = this.important1 + "<p>Your NACH mandate is for ₹ " + strecslimit + " and your current bill value is Amount ₹ " + nachAmt.toFixed(2) + "/- You are requested to make balance payment of ₹ " + nachAmt1.toFixed(2) + "/- by any of the prescribed modes within the due date.</p>";
          }
        }

        if (this.billData.arrdpc) {
          if (Number(this.billData.arrdpc) > 0) {
            this.important1 = this.important1 + "<p>Delayed Payment Charges and Late Payment Charges are levied due to delayed payment against your past bills and total of the same amounting to ₹ " + this.billData.arrdpc + "/- has been included under the Arrears amount.</p>";
          }
        }

        if (this.billData.intnetpay.toLowerCase() == "shubh labh") {
          this.important1 = this.important1 + "<p>Discount eligible under the Shub Labh Scheme is shown and adjusted against your gas dues in this bill.";
        }

        if (this.billData.CU_Charges) {
          if (Number(this.billData.CU_Charges) > 0) {
            this.important1 = this.important1 + "<p>Other charges in the current bill represent charges levied for Extra Copper Pipe/ Rubber Hose used in your residence as per MGL's Policy.</p>";
          }
        }


        if (this.billData.One_time_chrg) {
          if (Number(this.billData.One_time_chrg) > 0) {
            this.important1 = this.important1 + "<p>Other Charges includes amount payable against PNG Gas stove.</p>";
          }
        }

       


        //const billDate = new Date(this.billData.bill_date);
        const dtEMI_OPEN_From = new Date('01/05/2022');
        const dtEMI_OPEN_To = new Date('30/06/2022');
        if (billDate >= dtEMI_OPEN_From && billDate <= dtEMI_OPEN_To) {
          if (this.billData.EMI_OPEN.trim()) {
            if (Number(this.billData.EMI_OPEN) > 0) {
              //strInfo = "Additional Gas Consumption Security Deposit based on your consumption pattern in multiples of 100/- subject to a minimum of 750/- is charged & included as Arrears.";
              this.important1 = this.important1 + "<p>Additional Gas Consumption Security Deposit of ₹ " + this.billData.EMI_OPEN + " is charged & included as Arrears.</p>";
            }
          }
        }

        if (Number(this.billData.EMI_OPEN) > 0) {
          //strInfo = "Additional Gas Consumption Security Deposit based on your consumption pattern in multiples of 100/- subject to a minimum of 750/- is charged & included as Arrears.";
          this.important1 = this.important1 + "<p>Additional Gas Consumption Security Deposit of ₹ " + this.billData.EMI_OPEN + " is charged & included as Arrears.</p>";
        }

        if (this.billData.NAME_CHRG.trim()) {
          if (Number(this.billData.NAME_CHRG) > 0) {
            this.important1 = this.important1 + "<p>If you have requested for name transfer @ Rs. 350 /- is charged & included in Other Charges.</p>";
          }
        }

        

        var opening = this.billData.opening;
        if (opening) {
          if (opening.length >= 3) {

            if (opening.substring(opening.length - 3) == "(E)" || opening.substring(opening.length - 3) == "(A)") {
              this.previoseReading = opening.substring(0, opening.length - 3); //ds.Tables[0].Rows[0]["opening"].ToString().trim();
              this.assessedActual = "Assessed";
            }
            else {
              this.previoseReading = opening;
              this.assessedActual = "Actual";
            }
          }
          else {
            this.previoseReading = opening;
            this.assessedActual = "Actual";
          }
        }

        if (this.billData.p_mrdate != "00/00/0000" && this.billData.p_mrdate.trim() != "") {
          this.previousDate = this.billData.p_mrdate.trim();
        }
        else {
          this.previousDate = this.billData.metdtf.trim();
        }

        var closing = this.billData.closing.trim();
        if (closing) {
          if (closing.length >= 3) {
            if (closing.substring(closing.length - 3) == "(E)" || closing.substring(closing.length - 3) == "(A)") {
              this.closingReading = closing.substring(0, closing.length - 3); //ds.Tables[0].Rows[0]["closing"].ToString().trim();
              this.closingActual = "Assessed";
            }
            else {
              this.closingReading = closing;
              this.closingActual = "Actual";
            }
          }
          else {
            this.closingReading = closing;
            this.closingActual = "Actual";
          }
        }

        if (this.billData.MR_Note.trim() == "99") {
          this.closingDate = this.billData.MR_Date;
        }
        else {
          this.closingDate = this.billData.bill_date;
        }

        if (!this.billData.MR_Note) {
          this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"><tbody><tr>";
          this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"left\" valign=\"top\" style=\"font-size:9px;\">";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">As per the company policy, Bills are based on assessed basis. Hence, No photo image Printed</p>";
          this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "<td align=\"center\" valign=\"middle\" style=\"font-size:9px\">Tentative next meter reading date <br/> " + this.billData.n_mr_date + "</td>";
          this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }
        else if (this.billData.MR_Note == "0" || this.billData.MR_Note == "00" || this.billData.MR_Note == "17" || this.billData.MR_Note == "55" || this.billData.MR_Note == "66") {
          this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"><tbody><tr>";
          this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"center\" valign=\"top\" style=\"font-size:9px;\">";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">READER VISIT DATE <br/>" + this.billData.MR_Date + "</p>";
          // this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">TIME <br/> " + this.billData.MRTIME + "</p>";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">TIME <br/> " + this.billData.MRTime + "</p>";
          this.meterImgTxt = this.meterImgTxt + "<p style=\"font-size:9px; padding:0px 0 8px; margin: 0px;\">REMARK <br/> " + this.billData.MR_TEXT + "</p>";
          this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "<td align=\"center\" valign=\"middle\" style=\"font-size:9px\">Tentative next meter reading date <br/> " + this.billData.n_mr_date + "</td>";
          this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }
        else if (this.billData.MR_Note.trim() == "99") {
          const strMrDate = this.billData.MR_Date.replace("/", "_");
          const strPartFileName = this.billData.MR_DOC + "_" + this.billData.partner_no;
          const invoice = this.billData.TAX_INVNO.split('/');

          //this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\" style=\"border: none;\"><tbody><tr>";
          //this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"left\" valign=\"top\" style=\"font-size:9px;\">";

          //this.meterImgTxt = this.meterImgTxt + GetBillingMrText(this.billData.MR_Note);

          //this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "Tentative next meter reading date <br/> " + this.billData.n_mr_date;
          //this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }
        else {
          this.meterImgTxt = this.meterImgTxt + "<table width=\"100%\" border=\"0\" cellspacing=\"0\" cellpadding=\"0\"><tbody><tr>";
          this.meterImgTxt = this.meterImgTxt + "<td width=\"50%\" align=\"left\" valign=\"top\" style=\"font-size:9px;\">";

          //this.meterImgTxt = this.meterImgTxt + GetBillingMrText(this.billData.MR_Note);

          this.meterImgTxt = this.meterImgTxt + "</td>";
          this.meterImgTxt = this.meterImgTxt + "<td align=\"center\" valign=\"middle\" style=\"font-size:9px\">Tentative next meter reading date <br/> " + this.billData.n_mr_date + "</td>";
          this.meterImgTxt = this.meterImgTxt + "</tr></tbody></table>";
        }

        if (this.meterImgTxt) {
          this.mtrImgTxt = `<td width="50%" align="center" valign="top" style="font-size:9px;" [innerHTML]="meterImgTxt"></td>`
        }
        //} else {
        //  this.billData = data.filter((x: any) => {
        //    return x.bill_no == this.DashboardService.billno
        //  })
        //  this.billData = this.billData[0]
        //}
        // var pyblAmt = this.billData.amt_pybl;
         var pyblAmt = this.uptoTotal;
        if (!isNaN(this.billData.amt_pybl)) {
          var temp = this.billData.due_date.split("/")

          var date1 = new Date(temp[2] + "-" + temp[1] + "-" + temp[0]).getTime();
          var date2 = new Date().getTime();
          //alert(date2+ "   " + date1)
          if (date2 > date1) {

            // pyblAmt = this.dueAmt;
            pyblAmt = this.uptodueAmt;
          }
        }

        const upi = "upi://pay?pa=mahagasupi" + this.billData.contract_acc + "@hsbc&pn=MahanagarGasLtd&mc=4900&tid=&tr=" + this.billData.bill_no + "&tn=" + this.billData.bill_no + "&am=" + pyblAmt + "&mam=null&cu=INR";
        // this.qrdata_upi = "https://chart.googleapis.com/chart?chs=180x180&cht=qr&chl=" + encodeURIComponent(upi) + "&choe=UTF-8";
        this.qrdata_upi = "https://image-charts.com/chart?chs=180x180&cht=qr&choe=UTF-8&chl=" + encodeURIComponent(upi);
        const paytm = "paytmmp://utility?url=https://catalog.paytm.com/v1/mobile/getproductlist/78640?group=brand$title=gas$board=Mahanagar Gas- Mumbai$recharge_number=" + this.billData.contract_acc;
        // this.qrdata_paytm = "https://chart.googleapis.com/chart?chs=180x180&cht=qr&chl=" + encodeURIComponent(paytm) + "&choe=UTF-8";
        this.qrdata_paytm = "https://image-charts.com/chart?chs=180x180&cht=qr&choe=UTF-8&chl=" + encodeURIComponent(paytm);


        //console.log(this.billData.bill_date_history2)
        //var mydate = this.billData.bill_date_history2
        //var dateParts = mydate.split("/");

        // month is 0-based, that's why we need dataParts[1] - 1
        //var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]); 


        //console.log(this.billData)

         if (stramt_pybl.toUpperCase() == "CREDIT BILL" || stramt_pybl.toUpperCase() == "CREDIT BIL") {
          // console.log('this.totalPartA',this.totalPartA)
          //   console.log('this.vPartB',this.vPartB)
          var addtionImp = Number(this.totalPartA) + Number(this.vPartB);
            var addtionImp1 = addtionImp * -1;
          //console.log('addtionImp1',addtionImp1)
          this.important1 = this.important1 + "<p>This is a credit bill as past payment / credit is ₹ " + addtionImp1.toFixed(2) + "/- more than the amount due and the same shall be adjusted in future bills.</p>";
        }
    



       if (!isNaN(this.billData.amt_pybl)) {
          this.uptoTotal = Number(this.billData.amt_pybl) + Number(this.billData.EMI_ARR);
         // console.log('this.uptoTotal',this.uptoTotal)
        }else{
          this.uptoTotal = this.billData.amt_pybl
        }
       
       

        if (!isNaN(this.billData.amt_pybl)) {
          this.uptodueAmt = this.uptoTotal + 100;
        }

        

        this.loadChart()

        // this.billData.bill_date_history2 = dateObject
      })
    }
  }


  // loadChart(){ 
  //   //console.log('inchart')
  //    this.chart = new Chart('canvas', {
  //     type: 'bar',
  //     data: {
  //       // labels: [this.historyDate1, this.historyDate2, this.historyDate3, this.historyDate4, this.historyDate5, this.historyDate6],
  //       labels: [this.historyDate6, this.historyDate5, this.historyDate4, this.historyDate3, this.historyDate2, this.historyDate1],
  //       datasets: [
  //         {
  //           label: '',
  //           // data: [this.billData.unithis1, this.billData.unithis2, this.billData.unithis3, this.billData.unit_history4_scm, this.billData.unit_history5_scm, this.billData.unit_history6_scm],
  //           data: [this.billData.unit_history6_scm, this.billData.unit_history5_scm, this.billData.unit_history4_scm, this.billData.unithis3, this.billData.unithis2, this.billData.unithis1],
  //           borderWidth: 1,
  //           // barPercentage: 0.4,
  //         },
  //       ],
  //     },
  //     options: {

  //        animation: {
  //         duration: 1,
  //         onComplete: function({ chart }) {
  //           const ctx = chart.ctx;
        
  //           chart.config.data.datasets.forEach(function(dataset, i) {
  //             const meta = chart.getDatasetMeta(i);
        
  //             meta.data.forEach(function(bar, index) {
  //               var data :any
  //                data = dataset.data[index];
        
  //               ctx.fillText(data, bar.x, bar.y - 5);
  //             });
  //           });
  //         }
  //       },
        
  //       plugins: {
          
  //         legend: {
  //           display: false
  //       },
  //         title: {
  //           display: false,
  //           text: 'Chart.js Stacked Line/Bar Chart'
  //         }
  //       },
  //       scales: {
  //         y: {
  //           beginAtZero: true,
  //         },
           


  //       },

      
  //     },
  //   });
  // }

//   loadChart() {
//   // Preload the image you want to use in place of the bars
//   const customBarImage = new Image();
//   customBarImage.src = '../../../assets/images/chartimg/flame.png'; // Replace with your image URL

//   customBarImage.onload = () => {
//     // Initialize the chart after the image is loaded
//     this.chart = new Chart('canvas', {
//       type: 'bar',
//       data: {
//       //  labels: [this.historyDate6, this.historyDate5, this.historyDate4, this.historyDate3, this.historyDate2, this.historyDate1],
//          labels: [[this.historyDate6,this.historyDate66], [this.historyDate5,this.historyDate55], [this.historyDate4,this.historyDate44], [this.historyDate3,this.historyDate33], [this.historyDate2,this.historyDate22], [this.historyDate1,this.historyDate11]],
//         datasets: [
//           {
//             label: '',
//             data: [
//               this.billData.unit_history6_scm,
//               this.billData.unit_history5_scm,
//               this.billData.unit_history4_scm,
//               this.billData.unithis3,
//               this.billData.unithis2,
//               this.billData.unithis1,
//             ],
//            // borderWidth: 1,
//             backgroundColor: 'transparent', // Make the bars transparent
//           },
//         ],
//       },
//       options: {
//         animation: {
//           duration: 1,
//           onComplete: function({ chart }) {
//             const ctx = chart.ctx;

//              ctx.font = 'bold 11px Arial'; // Font style for labels
//            ctx.fillStyle = '#000000';    // Label color (red in this case)
//           ctx.textAlign = 'center';
//         ctx.textBaseline = 'bottom';

//             chart.config.data.datasets.forEach(function(dataset, i) {
//               const meta = chart.getDatasetMeta(i);

//               meta.data.forEach(function(bar, index) {
//                 var data: any;
//                 data = dataset.data[index];

//                 ctx.fillText(data, bar.x, bar.y - 5);
//               });
//             });
//           },
//         },
//         plugins: {
//           legend: {
//             display: false,
//           },
//           title: {
//             display: false,
//             text: 'Chart.js Stacked Line/Bar Chart',
//           },
//         },
//         scales: {
//           y: {
//             beginAtZero: true,
//             title: {
//           display: true, // You can also add a title to the x-axis
//           text: 'SCM',
//           color: '#014d7d', // Customize label color (optional)
//            font:{
//             weight: 'bold' // Optionally set the font weight
//            }
//         },
//         grid: {
//           display: false // Hide grid lines for the x-axis
//         },
//         ticks: {
//           color: '#014d7d', // Set the color of the y-axis values (red)
//           font: {
//             size: 10, // Optionally set the font size
//             weight: 'bold' // Optionally set the font weight
//           }
//         },border: {
//         color: '#014d7d', // X-axis line color
//       }
//           },
//           x: {
//          grid: {
//           display: false // Hide grid lines for the x-axis
//         },
//         ticks: {
//           color: '#014d7d', // Set the color of the y-axis values (red)
//           font: {
//             size: 10, // Optionally set the font size
//             weight: 'bold' // Optionally set the font weight
//           },

//           //  callback: function(value) {
//           //   // Split the label into separate lines for wrapping
//           //  return String(value).split(" ").join("\n");
//           // },
//           // maxRotation: 0, // Prevent rotation of ticks
//           // minRotation: 0   // Prevent rotation of ticks
//         },
//         border: {
//         color: '#014d7d', // X-axis line color
//       }
//       },
//         },
//       },
//       plugins: [
//         {
//           id: 'customBarImages',
//           afterDatasetsDraw: function(chart) {
//             const ctx = chart.ctx;
//             const dataset = chart.data.datasets[0];
//             const meta = chart.getDatasetMeta(0);

//             // Loop through each bar and draw the image at its position
//             meta.data.forEach(function(bar, index) {
//               const { x, y, base } = bar.getProps(['x', 'y', 'base']);
//               const barWidth = 20;  // Set a default width if needed
//               const barHeight = base - y; // Get the height based on base and y position

//               // Draw the custom image inside the bar's location
//               ctx.drawImage(customBarImage, x - barWidth / 2, y, barWidth, barHeight);
//             });
//           },
//         },
//       ],
//     });
//   };
// }

  loadChart() {
  // Preload the image you want to use in place of the bars
  const customBarImage = new Image();
  customBarImage.src = '../../../assets/images/chartimg/flame.png'; // Replace with your image URL

  customBarImage.onload = () => {
    // Initialize the chart after the image is loaded
    this.chart = new Chart('canvas', {
      type: 'bar',
      data: {
        labels: [[this.historyDate6,this.historyDate66], [this.historyDate5,this.historyDate55], [this.historyDate4,this.historyDate44], [this.historyDate3,this.historyDate33], [this.historyDate2,this.historyDate22], [this.historyDate1,this.historyDate11]],
        datasets: [
          {
            label: '',
            data: [
              this.billData.unit_history6_scm,
              this.billData.unit_history5_scm,
              this.billData.unit_history4_scm,
              this.billData.unithis3,
              this.billData.unithis2,
              this.billData.unithis1,
            ],
           // borderWidth: 1,
            backgroundColor: 'transparent', // Make the bars transparent
          },
        ],
      },
      options: {
        animation: {
          duration: 1,
          onComplete: function({ chart }) {
            const ctx = chart.ctx;

        //      ctx.font = 'bold 11px Arial'; // Font style for labels
        //    ctx.fillStyle = '#000000';    // Label color (red in this case)
        //   ctx.textAlign = 'center';
        // ctx.textBaseline = 'bottom';



            chart.config.data.datasets.forEach(function(dataset, i) {
              const meta = chart.getDatasetMeta(i);

              meta.data.forEach(function(bar, index) {
                // var data: any;
                // data = dataset.data[index];

                // ctx.fillText(data, bar.x, bar.y - 5);
                 const data = dataset.data?.[index]; // Use optional chaining to safely access data

    if (data != null) { // Check if data is not null or undefined
      const text = data.toString(); // Convert to string for display

      // Set the font before measuring the text
      // ctx.font = '12px Arial';

      // Measure text width and set text height (adjust based on the font size)
      const textWidth = ctx.measureText(text).width;
      const textHeight = 12; // Adjust this based on the font size
        const padding = 4; // Set padding around the text

      // Set background color
      ctx.fillStyle = '#d89d9d'; // Background color

      // Draw the background rectangle behind the text
      // ctx.fillRect(bar.x - textWidth / 2, bar.y - textHeight - 5, textWidth, textHeight);
        ctx.fillRect(
        bar.x - (textWidth / 2) - padding,   // Center the background horizontally with padding
        bar.y - textHeight - padding - 5,    // Position it above the bar with padding
        textWidth + padding * 2,             // Adjust width to include padding
        textHeight + padding * 2             // Adjust height to include padding
      );


      // Set text color and draw the text
      // ctx.fillStyle = 'black';
            ctx.font = 'bold 11px Arial'; // Font style for labels
           ctx.fillStyle = '#000000';    // Label color (red in this case)
        //   ctx.textAlign = 'center';
        // ctx.textBaseline = 'bottom';
      ctx.fillText(text, bar.x - textWidth / 2, bar.y - 5);
    }
              });
            });
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: 'Chart.js Stacked Line/Bar Chart',
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
          display: true, // You can also add a title to the x-axis
          text: 'SCM',
          color: '#014d7d', // Customize label color (optional)
           font:{
            weight: 'bold' // Optionally set the font weight
           }
        },
        grid: {
          display: false // Hide grid lines for the x-axis
        },
        ticks: {
          color: '#014d7d', // Set the color of the y-axis values (red)
          font: {
            size: 10, // Optionally set the font size
            weight: 'bold' // Optionally set the font weight
          }
        },
        border: {
        color: '#014d7d', // X-axis line color
      }
          },
          x: {
         grid: {
          display: false // Hide grid lines for the x-axis
        },
        ticks: {
          color: '#014d7d', // Set the color of the y-axis values (red)
          font: {
            size: 10, // Optionally set the font size
            weight: 'bold' // Optionally set the font weight
          },

          //  callback: function(value) {
          //   // Split the label into separate lines for wrapping
          //  return String(value).split(" ").join("\n");
          // },
          // maxRotation: 0, // Prevent rotation of ticks
          // minRotation: 0   // Prevent rotation of ticks
        },
         border: {
        color: '#014d7d', // X-axis line color
      }
      },
        },
      },
      plugins: [
        {
          id: 'customBarImages',
          afterDatasetsDraw: function(chart) {
            const ctx = chart.ctx;
            const dataset = chart.data.datasets[0];
            const meta = chart.getDatasetMeta(0);

            // Loop through each bar and draw the image at its position
            meta.data.forEach(function(bar, index) {
              const { x, y, base } = bar.getProps(['x', 'y', 'base']);
              const barWidth = 20;  // Set a default width if needed
              const barHeight = base - y; // Get the height based on base and y position

              // Draw the custom image inside the bar's location
              ctx.drawImage(customBarImage, x - barWidth / 2, y, barWidth, barHeight);
            });
          },
        },
      ],
    });
  };
}


  

  getBillInfo(strDate: any) {
    if (strDate) {
      //console.log(strDate)
      const temp: string[] = strDate.split("/")
      // console.log('temp',temp)
      const date = new Date();
      date.setDate(Number(temp[0]));
      date.setMonth(Number(temp[1]) - 1);
      date.setFullYear(Number(temp[2]));

      const date1 = new Date(Number(temp[2]), Number(temp[1]) - 1, Number(temp[0]));  // 2009-11-10
const month = date1.toLocaleString('default', { month: 'short' });
//console.log(month);

      // return this.datepipe.transform(new Date(date.setDate(date.getDate() + 1)), 'dd/MM/yyyy')
       return month
    } else {
      return ''
    }

  }

  getBillInfoYear(strDate: any) {
    if (strDate) {
     // console.log(strDate)
      const tempYear: string[] = strDate.split("/")
       return Number(tempYear[2])
    } else {
      return ''
    }

  }

//   printPdf() {

//     // var date = this.datePipe.transform(this.UserForm2.get('DOB')?.value, 'dd-MM-yyyy')
//     // var currDate = this.datePipe.transform(new Date(), 'dd MMM, yyyy')
//     const strEmail = this.email ? "Email: "+this.email : '';
//     var Applications: any = `
//     <!doctype html>
// <html>

// <head>
//   <meta charset="utf-8">

// </head>

// <body>
//   <table width="840px" border="0" cellspacing="0" cellpadding="0"
//     style="font-family:Arial, 'sans-serif'; font-size:10px; margin: 0 auto">
//     <tbody>
//       <tr>
//         <td>
//           <table width="100%" border="0" cellspacing="0" cellpadding="0">
//             <tbody>
//               <tr>
//                 <td width="66.4%" valign="top">
//                   <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                     style="border:2px solid #52b049; border-radius:6px; padding:5px 10px">
//                     <tbody>
//                       <tr>
//                         <td width="14%"><img src="../../../assets/images/logo.png" width="65" alt="" /></td>
//                         <td width="86%" align="center" valign="top">
//                           <h3
//                             style="font-size: 12px; background:#52b049; padding:3px 10px; display: inline-block; color:#FFF; border-radius: 40px; width:160px; text-align: center; margin: 0 0 7px 0; font-size: 12px;-webkit-print-color-adjust: exact;">
//                             TAX INVOICE</h3>
//                           <h2
//                             style="font-size: 16px; line-height: 16px; font-weight:600; text-align: center;color: #0074bb; text-transform: uppercase; margin: 0 0 2px 0">
//                             MAHANAGAR GAS LIMITED</h2>
//                           <p style="margin:0 0 5px ">www.mahanagargas.com</p>
//                           <p style="color:#52b049; margin:0 0 0px; font-size: 12px "><strong>An ISO 9001, 14001, and
//                               45001 Certified Company</strong></p>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </td>
//                 <td width="1%">&nbsp;</td>
//                 <td width="250px" valign="top">
//                   <table width="100%" border="0" cellspacing="0"
//                     style="width: 100%; border: 2px solid #52b049; border-radius: 6px; box-sizing: border-box;  background: #edf5e8; font-size: 12px">
//                     <tbody>
//                       <tr>
//                         <td style="border:1px solid #52b049; border-width:0 1px 1px 0; padding:8px 5px"><strong>CA
//                             No</strong></td>
//                         <td style="border:1px solid #52b049; border-width:0 0 1px 0; padding:8px 5px">
//                           <strong>${this.billData.contract_acc}</strong></td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52b049; border-width:0 1px 1px 0; padding:8px 5px"><strong>Bill
//                             Date</strong></td>
//                         <td style="border:1px solid #52b049; border-width:0 0 1px 0; padding: 8px 5px">
//                           <strong>${this.billData.bill_date}</strong></td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52b049; border-width:0 1px 0 0; padding:8px 5px"><strong>Billing
//                             Period</strong></td>
//                         <td style="padding:8px 5px"><strong>${this.previousDate} TO ${this.closingDate}</td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </td>
//               </tr>
//               <tr>
//                 <td valign="top">
//                   <table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:4px 0 0 0">
//                     <tbody>
//                       <tr>
//                         <td valign="top">
//                           <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                             style="border:2px solid #52b049; border-radius:6px; padding:5px 5px; background:#fceee5; height:82px;-webkit-print-color-adjust: exact;">
//                             <tbody>
//                               <tr>
//                                 <td align="center">
//                                   <h2 style="margin: 0 0; font-size:11px">Emergency</h2>
//                                 </td>
//                               </tr>
//                               <tr>
//                                 <td align="center" valign="top">
//                                   <p style="margin:0 0; font-size:8px">(Gas Leak, Gas Stop, Fire)</p>
//                                   <h5 style="color:#e80717; font-size: 10px; margin: 1px 0 2px 0">18002669944 (Tollfree)<br>
//                                     (022)-68759400,<br>
//                                     (022)-24012400</h5>
//                                   <p style="margin: 5px 0 0">(Available 24X7)</p>
//                                 </td>
//                               </tr>
//                             </tbody>
//                           </table>
//                         </td>
//                         <td>&nbsp;</td>
//                         <td valign="top">
//                           <div style="border: 2px solid #52b049; border-radius: 6px; padding:6px; min-height:86px">
//                             <h2
//                               style="font-size: 10px; line-height: 12px; font-weight:600; color: #000; text-transform: uppercase; padding: 0 2px 0 4px; margin: 0;">
//                               ${this.billData.name}</h2>
//                             <p
//                               style="font-size: 8px; line-height: 10px; font-weight: 600; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">
//                               ${this.details}</p>
//                             <p
//                               style="font-size: 8px; line-height: 10px; font-weight: 600; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">
//                               ${this.flat}</p>
//                             <p
//                               style="font-size: 8px; line-height: 10px; font-weight: 600; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">
//                               ${this.landmark}</p>
//                             <p
//                               style="font-size: 8px; line-height: 10px; font-weight: 600; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">
//                               ${this.city}</p>
//                             <p
//                               style="font-size: 8px; line-height: 10px; font-weight: 600; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">
//                               Mob: ${this.mobile} | ${strEmail}</p>
//                             <p
//                               style="font-size: 8px; line-height: 10px; font-weight: 600; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">
//                               BP No. ${this.billData.partner_no}</p>
//                           </div>
//                         </td>
//                       </tr>
//                     </tbody>
//                   </table>
//                 </td>
//                 <td>&nbsp;</td>
//                 <td valign="top">
//                   <div style="display:flex;justify-content: space-between; margin-top: 4px ">
//                     <div
//                       style="border: 2px solid #52b049; border-radius: 6px; padding: 6px; text-align: center; width: 48%; margin-right: 5px; height:86px ">
//                       <h3 style="margin:0 0 0; font-size: 12px; font-weight: normal">Due Amount</h3>
//                       <p style="margin:2px 0; font-size: 16px; font-weight: 700">${this.billData.amt_pybl}</p>
//                       <p style="margin:0 0 5px; font-size: 12px; font-weight: normal">Due Date</p>
//                       <p style="margin:0 0 0; font-size: 14px; font-weight: 700">${this.dueDate}</p>
//                     </div>
//                     <div
//                       style="border: 2px solid #52b049; border-radius: 6px; padding: 6px; text-align: center;  width: 48%; height:86px">
//                       <h3 style="margin:0 0 5px; font-size: 12px; font-weight: normal">Amount After Due Date</h3>
//                       <p style="margin:2px 0; font-size: 16px; font-weight: 700">${this.dueAmt}</p>
//                     </div>
//                   </div>
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </td>
//       </tr>
//       <tr>
//         <td></td>
//       </tr>

//       <tr>
//         <td>
//           <div style="display:flex; justify-content: space-between; margin-top: 4px">
//             <div style="width: 66.5%">
//               <table width="100%" border="0" cellspacing="0" cellpadding="2"
//                 style="border:2px solid #52b049; border-radius: 6px; ">
//                 <tbody>
//                   <tr>
//                     <td height="18" colspan="3"
//                       style="background:#52b049;-webkit-print-color-adjust: exact; border-radius:4px 4px 0 0;">&nbsp;
//                     </td>
//                   </tr>
//                   <tr>
//                     <td width="43%" style="border:1px solid #52b049; border-width:0 1px 1px 0 ">MVAT Invoice No</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 " width="4%">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 " width="53%">
//                       <strong>${this.billData.TAX_INVNO}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>SALE OF NATURAL GAS  @ Rate Per SCM <b>₹ ${this.billData.rate}</b></strong>
//                     </td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">
//                       <strong><strong>₹</strong>${this.billData.cur_cons}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>Gas Consumption SCM
//                         <strong></strong></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">
//                       <strong><strong>₹</strong>${this.billData.cons}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>MVAT @3%
//                         <strong></strong></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">
//                       <strong><strong>₹</strong>${this.billData.vat}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 ">
//                       <strong>Arrears<strong></strong></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">
//                       <strong><strong>₹</strong>${this.str_arrears_emi}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>Credit Balance / Discount /
//                         Rebate</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">
//                       <strong><strong>₹</strong>${this.v_creditbalance}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>TOTAL CHARGES A</strong>
//                     </td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 "><strong><strong>₹
//                           ${this.totalPartA.toFixed(2)}</strong></strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 " colspan="3">
//                       <table width="100%" border="0" cellspacing="0" cellpadding="0">
//                         <tbody>
//                           <tr>
//                             <td width="23%"><strong>GST Invoice No.</strong></td>
//                             <td width="77%"><strong>${this.billData.bill_no}</strong></td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>Other Charges</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">${this.otherCharges.toFixed(2)}</td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>Minimum Charges</strong>
//                     </td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">${this.billData.min_chg}</td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>SGST
//                         @9%<strong></strong></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">${this.v_SGST.toFixed(2)}</td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>CGST @9%</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">${this.v_CGST.toFixed(2)}</td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>TOTAL CHARGES B</strong>
//                     </td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">${this.vPartB.toFixed(2)}</td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 "><strong>TOTAL PAYABLE
//                         (A+B)<strong></strong></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">&nbsp;</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">${this.abTotal.toFixed(2)}</td>
//                   </tr>
//                   <tr>
//                     <td colspan="3"><strong style="border-bottom:1px solid #52b049; display:block; padding:2px; margin:0 -2px 2px">Gas Consumption Security Deposit <span
//                           id="ContentPlaceHolder1_lblSecurity_68">₹ ${this.billData.EMI_CLEARED}.</span></strong><strong>
//                         ${this.str5000}</strong></td>
//                   </tr>

//                 </tbody>
//               </table>
//               <div style="display:flex; margin-top: 4px">
//                 <div
//                   style="border: 2px solid #52b049; border-radius: 6px; padding:5px 10px; min-height:65px; margin-right: 8px; width: 33.33%; text-align: center">
//                   <p style="margin:0 0 5px">Meter No.</p>
//                   <h3 style="font-size:12px">${this.billData.meter_no} </h3>
//                 </div>
//                 <div
//                   style="border: 2px solid #52b049; border-radius: 6px; padding:5px 10px; min-height:65px;margin-right: 8px; width: 33.33%; text-align: center">
//                   <p style="margin:0 0 1px">Previous Reading</p>
//                   <h3 style="font-size:10px; margin:0 0 2px">${this.previoseReading}</h3>
//                   <h3 style="font-size:10px; margin:0 0 2px">${this.assessedActual}</h3>
//                   <p style="margin:0 0 2px">Date</p>
//                   <h3 style="font-size:10px; margin:0 0 2px">${this.previousDate}</h3>
//                 </div>
//                 <div
//                   style="border: 2px solid #52b049; border-radius: 6px; padding:5px 10px; min-height:65px; width: 33.33%; text-align: center">
//                   <p style="margin:0 0 2px">Closing Reading</p>
//                   <h3 style="font-size:10px; margin:0 0 2px">${this.closingReading}</h3>
//                   <h3 style="font-size:10px; margin:0 0 2px">${this.closingActual}</h3>
//                   <p style="margin:0 0 5px">Date</p>
//                   <h3 style="font-size:10px; margin:0 0 2px">${this.closingDate}</h3>
//                 </div>

//               </div>
//               <table width="100%" border="0" cellspacing="0" cellpadding="2"
//                 style="margin-top: 4px; font-size: 11px; border:2px solid #52b049; border-radius: 8px;word-break: break-all;">
//                 <tbody>
//                   <tr>
//                     <td colspan="5" align="center"
//                       style="background:#52b049; -webkit-print-color-adjust: exact; border-radius:6px 6px 0 0; font-size: 12px; color:#FFF; font-weight: 600; border: 2px solid #52b049; ">
//                       Billing History</td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0">Graph</td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0">Period</td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 ">SCM</td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0 ">Type Of Reading</td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0 ">Amount <strong>₹</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0;text-align:center"><strong
//                         id="ContentPlaceHolder1_lblGraph_1"><img src="../../../assets/images/graph-img.jpg" alt=""
//                           width="${this.billData.unithis1}px" height="15px"></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong>${this.historyDate1}
//                       </strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0">
//                       <strong>${this.billData.unithis1}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblReadingType_1">${this.billData.reading_type_history1}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0"><strong
//                         id="ContentPlaceHolder1_lblAmount_1">${this.billData.amthis1}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0;text-align:center"><strong
//                         id="ContentPlaceHolder1_lblGraph_2"><img src="../../../assets/images/graph-img.jpg" alt=""
//                           width="${this.billData.unithis2}px" height="15px"></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblPeriod_2">${this.historyDate2}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblScm_2">${this.billData.unithis2}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblReadingType_2">${this.billData.reading_type_history2}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0"><strong
//                         id="ContentPlaceHolder1_lblAmount_2">${this.billData.amthis2}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0;text-align:center"><strong
//                         id="ContentPlaceHolder1_lblGraph_3"><img src="../../../assets/images/graph-img.jpg" alt=""
//                           width="${this.billData.unithis3}px" height="15px"></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblPeriod_3">${this.historyDate3}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblScm_3">${this.billData.unithis3}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblReadingType_3">${this.billData.reading_type_history3}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0"><strong
//                         id="ContentPlaceHolder1_lblAmount_3">${this.billData.amthis3}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0;text-align:center"><strong
//                         id="ContentPlaceHolder1_lblGraph_4"><img src="../../../assets/images/graph-img.jpg" alt=""
//                           width="${this.billData.unit_history4_scm}px" height="15px"></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblPeriod_4">${this.historyDate4}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblScm_4">${this.billData.unit_history4_scm}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblReadingType_4">${this.billData.reading_type_history4}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0"><strong
//                         id="ContentPlaceHolder1_lblAmount_4">${this.billData.amount_history4_scm}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0;text-align:center"><strong 
//                         id="ContentPlaceHolder1_lblGraph_5"><img src="../../../assets/images/graph-img.jpg" alt=""
//                           width="${this.billData.unit_history5_scm}px" height="15px"></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblPeriod_5">${this.historyDate5}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblScm_5">${this.billData.unit_history5_scm}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 1px 0"><strong
//                         id="ContentPlaceHolder1_lblReadingType_5">${this.billData.reading_type_history5}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 0 1px 0"><strong
//                         id="ContentPlaceHolder1_lblAmount_5">${this.billData.amount_history5_scm}</strong></td>
//                   </tr>
//                   <tr>
//                     <td style="border:1px solid #52b049; border-width:0 1px 0 0;text-align:center"><strong
//                         id="ContentPlaceHolder1_lblGraph_6"><img src="../../../assets/images/graph-img.jpg" alt=""
//                           width="${this.billData.unit_history6_scm}px" height="15px"></strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 0 0"><strong
//                         id="ContentPlaceHolder1_lblPeriod_6">${this.historyDate6}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 0 0"><strong
//                         id="ContentPlaceHolder1_lblScm_6">${this.billData.unit_history6_scm}</strong></td>
//                     <td style="border:1px solid #52b049; border-width:0 1px 0 0"><strong
//                         id="ContentPlaceHolder1_lblReadingType_6">${this.billData.reading_type_history6}</strong></td>
//                     <td><strong id="ContentPlaceHolder1_lblAmount_6">${this.billData.amount_history6_scm}</strong></td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//             <div style="width:32.5%; display:flex; flex-direction: column;justify-content: flex-end;">
//               <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                 style="border:2px solid #52b049; border-radius: 6px; height:100%">
//                 <tbody>
//                   <tr>
//                     <td height="25" align="center"
//                       style="background:#52b049; color:#FFF; font-size: 12px; font-weight: 600;-webkit-print-color-adjust: exact;">
//                       IMPORTANT INFORMATION</td>
//                   </tr>
//                   <tr>
//                     <td style="padding:5px 10px; vertical-align: top;">
//                       <p>${this.important1}</p>
//                        <p>${this.importantNew ? this.importantNew:''}</p>
//                         <p>${this.importantNew1 ? this.importantNew1:''}</p>
//                       <!--<p style="font-size: 12px">We thank you for paying ₹${this.billData.amthis1}/- against the PNG bill dated {{billData.bill_date_history1}}</p>
//               <p  style="font-size: 12px">For registering your Mobile number or E-mail id, Please visit https://www.mahanagargas.com/residential-png/customer-zone/customer-login/edit-profile</p>-->
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//               <div style="width:auto; border: 2px solid #52b049; border-radius: 6px; margin-top:8px; padding:8px">
//                 <table width="100%" border="0" cellspacing="0" cellpadding="5" style="border:1px solid #dee2e6">
//                   <tbody>
//                     <tr>
//                     ${this.mtrImg}
//                       <td width="50%" align="center" valign="top" style="font-size:9px;"><span>${this.meterImgTxt}</span></td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//               <div style="width:auto; border: 2px solid #52b049; border-radius: 6px; margin-top:8px; padding:8px; float:left">
//                 <small style="width: 100%; float: left; margin-bottom: 6px;"><strong>Declaration</strong>It is hereby certified that Registration
//                   Certificate of the Company issued under Maharashtra Value Added Tax Act, 2002 is
//                   in force as on date and that the transaction of sale covered under this Tax
//                   Invoice shall be accounted for in the Turnover of Sales while filing of Returns
//                   and tax payable on the sale, if any, has been paid or shall be paid.</small>
//                 <div class="declarationLeft" style="float:left;width: 60%;">
//                   <strong style="display:block;font-size: 8px;font-weight:600; color:#000; padding-bottom:5px;">For Mahanagar Gas Ltd</strong>
//                   <img src="../../../assets/images/chief-manager-signature.jpg" alt="" style="width: 30px; display: block; padding: 1px 0 6px 6px;">
//                   <b style="font-size: 8px;font-weight:600;">Chief Manager - Revenue &amp; Taxation</b>
//                 </div>
//                 <div class="declarationRight" style="float:left; width:37%; padding: 0 0 6px 6px;">
//                   <img style="width:45px; display: block;float: right;padding: 6px 12px 0 0;" src="../../../assets/images/newformat_stamp_img2.jpg" alt="" >
//                 </div>
//               </div>
//             </div>
//           </div>
//         </td>
//       </tr>
//       <tr>
//         <td>
//           <div
//             style="width:auto; border: 2px solid #52b049; border-radius: 6px; margin-top:8px; padding:8px; font-size: 8px; font-weight:500">
//             <p style="margin: 0"><strong>Dear Consumer,</strong></p>
//             <p style="margin:4px 0; font-size:8px"><strong>
//            Download <small class="text-img-connect"><img
//                   src="../../../assets/images/mgl-connect-logo-sml.jpg" style="height:6px;"></small> app to Provide Meter reading between <em id="ContentPlaceHolder1_lblMrDate1">${this.billData.n_mr_date1}</em> and
//               <em id="ContentPlaceHolder1_lblMrDate2">${this.billData.n_mr_date2}</em>.Also you can Self Generate your PNG Bill & Pay Between <em>${this.billData.desp_date}</em> to <em>${this.billData.due_date}</em>
//             </strong></p>
//               <p style="margin-bottom:2px; margin-top:2px; text-align:center;"><strong>AND</strong></p>   
//                                     <strong>OPT for paperless Invoice by sending SMS to 9223555557 "No Hard Copy".</strong><br>
//             <strong style="color:green">To check the credentials of Meter Reader / After Sales
//               Supervisor</strong>
//             <p style="margin:5px 0 0;font-size:8px"> Open the SMS received from MGL on your registered Mobile number
//               &rarr; Click on the link in the SMS &rarr; Photo of the Meter Reader / After Sales
//               Supervisor will be displayed.</p>

//           </div>
//         </td>
//       </tr>
//       <tr>
//         <td>
//           <div style="display: flex; margin-top: 4px; justify-content: space-between;">
//             <div style="width: 49.5%">
//               <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                 style="border:2px solid #52b049; border-radius: 6px; height:142px">
//                 <tbody>
//                   <tr>
//                     <td height="25" align="center"
//                       style="background:#52b049; -webkit-print-color-adjust: exact; color:#FFF; font-size: 12px; font-weight: 600">
//                       PAYMENT SLIP/CHEQUE
//                     </td>
//                   </tr>
//                   <tr>
//                     <td valign="top">
//                       <table width="100%" border="0" cellspacing="0" cellpadding="3">
//                         <tbody>
//                           <tr>
//                             <td style="border: 2px solid #52b049; border-width: 0 2px 2px 0">Cheque No:</td>
//                             <td style="border: 2px solid #52b049; border-width: 0 0 2px 0">Cheque Dt:</td>
//                           </tr>
//                           <tr>
//                             <td colspan="2" style="border: 2px solid #52b049; border-width: 0 0 2px 0"><strong>Bank
//                                 Name/ branch:</strong></td>
//                           </tr>
//                           <tr>
//                             <td style="border: 2px solid #52b049; border-width: 0 2px 2px 0"><strong>CA:</strong><small
//                                 id="ContentPlaceHolder1_lblCaNo_39">${this.billData.contract_acc} </small></td>
//                             <td style="border: 2px solid #52b049; border-width: 0 0 2px 0"><strong>Due
//                                 date:</strong>${this.billData.due_date}</td>
//                           </tr>
//                           <tr>
//                             <td style="border: 2px solid #52b049; border-width: 0 0 2px 0" colspan="2"><strong>Amount
//                                 Paid:</strong><em
//                                 id="ContentPlaceHolder1_lblAmount_Paid_42">${this.billData.amt_pybl}</em></td>
//                           </tr>
//                           <tr>
//                             <td colspan="2">Please pay by cheque in favour of <br>
//                               "Mahanagar Gas Ltd CA No. ${this.billData.contract_acc}"</td>
//                           </tr>
//                         </tbody>
//                       </table>

//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//             <div style="width: 49.5%">
//               <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                 style="border:2px solid #52b049; border-radius: 6px">
//                 <tbody>
//                   <tr>
//                     <td height="25" align="center"
//                       style="background:#52b049; -webkit-print-color-adjust: exact; color:#FFF; font-size: 12px; font-weight: 600">
//                       Pay through QR (Quick Response) Code
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <table width="100%" border="0" cellspacing="0" cellpadding="5" style="height: 90px">
//                         <tbody>
//                           <tr>
//                             <td width="50%" align="center" valign="top" style="border-right: 2px solid #52b049">
//                               <img src="../../../assets/images/upi-logo.png"
//                                 style="position:relative; margin-bottom:-10px;">
//                               <img src="${this.qrdata_upi}" width="100" style="display:block;margin-top:-5px" />
//                             </td>
//                             <td align="center" valign="top">
//                               <img src="../../../assets/images/paytm-logo.png"
//                                 style="position:relative; margin-top:2px;">
//                               <img src="${this.qrdata_paytm}" width="90" style="display:block; margin-top:-7px" />
//                             </td>
//                           </tr>


//                         </tbody>
//                       </table>

//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </td>
//       </tr>
//       <tr>
//         <td align="center">
//           <p style="font-size: 11px; margin: 10px 0 5px 0"><strong>Corporate &amp; Registered Office: MGL House, G-33,
//               Bandra-Kurla Complex, Bandra (East), Mumbai - 400 051  CIN No. L40200MH1995PLC088133</strong> </p>
        
//         </td>
//       </tr>
//       <tr>
//         <td align="center">
//           <h4
//             style="background:#52b049; padding: 8px 0; color:#FFF; font-size: 12px; text-align: center; margin:5px 0;-webkit-print-color-adjust: exact;">
//             VAT NO. 27390000364V | C.S.T. No. 27390000364C | GSTIN Number : 27AABCM4640G1ZN | PLACE OF SUPPLY :
//             MAHARASHTRA (27)</h4>
//           <p style="margin: 0px 0 5px 0"><strong>Subject to Mumbai Jurisdiction</strong></p>
//           <div><img src="../../../assets/images/current-bill-ad-new.jpg" width="100%" style="border-radius:6px; border:2px solid #52b049;"></div>
//         </td>
//       </tr>
//     </tbody>
//   </table>
//   <div style="page-break-before:always"></div>
//   <table width="890px" border="0" cellspacing="0" cellpadding="0"
//     style="font-family:Arial, 'sans-serif'; font-size:12px; margin: 0 auto">
//     <tbody>

//       <tr>
//         <td align="center">
//           <div style="display: flex; justify-content: space-between;">
//             <div style="width: 49.5%">
//               <div
//                 style="width:auto; border: 2px solid #52b049; border-radius: 6px; margin-top:0px; padding:8px; background: #e6f3e3;-webkit-print-color-adjust: exact;">
//                 <img src="../../../assets/images/current-bill-new-hindi-text.jpg" width="100%">
//               </div>

//               <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                 style="border:2px solid #52b049; border-radius: 6px; margin-top: 4px">
//                 <tbody>
//                   <tr>
//                     <td height="25" align="center"
//                       style="background:#52b049; color:#FFF; font-size: 12px; font-weight: 600;-webkit-print-color-adjust: exact;">
//                       Safety - Best Practices</td>
//                   </tr>
//                   <tr>
//                     <td bgcolor="#E6F3E3" style="padding:5px 10px; -webkit-print-color-adjust: exact;">
//                       <ul style="padding: 0px 8px 0px 15px; margin0">
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           Ensure Appliance Valve / Meter Control Valve is
//                           closed when not using Gas.</li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           Switch off Gas when attending telephone calls.
//                         </li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           Keep windows open and allow air circulation
//                           inside Kitchen to facilitate ventilation.</li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           Electric Installation / Wiring should never touch
//                           or traverse Gas line. Please maintain a clear distance of at least 01 foot
//                           (300 mm).</li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           Do not conceal Gas Pipes in permanent or
//                           temporary structures.</li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           Gas Geysers must NOT be installed inside
//                           bathrooms or closed areas. Please contact ONLY MGL approved contractors for
//                           installation of Gas Geysers. Details are available at <a style="color:#3f3b90"
//                             href="https://www.mahanagargas.com/residential-png/gas-geysers/rate-card"
//                             target="_blank">https://www.mahanagargas.com/residential-png/gas-geysers/rate-card</a>
//                         </li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           For repair / change of Gas Stove / Rubber Tube /
//                           Temporary Disconnection (Renovation) etc. please avail services from MGL
//                           authorized contractors. Details of such contractors are available at <a style="color:#3f3b90"
//                             href="https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/service-provider"
//                             target="_blank">https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/service-provider</a>
//                           and the schedule of rates for material and service charges are available at
//                           <a style="color:#3f3b90"
//                             href="https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/png-service-charges"
//                             target="_blank">https://www.mahanagargas.com/residential-png/policies-and-procedures/aftersales-services/png-service-charges</a>.
//                           Please check the credentials of After Sales Supervisor as per procedure
//                           mentioned above.
//                         </li>
//                         <li
//                           style="text-align:justify;font-size: 10px; line-height: 12px; font-weight: 400; color: #000; padding-bottom: 2px;">
//                           For viewing "Natural Gas Safety Awareness Film"
//                           please visit the link <a style="color:#3f3b90"
//                             href="https://www.mahanagargas.com/residential-png/customer-zone/consumer-safty-checklist"
//                             target="_blank">https://www.mahanagargas.com/residential-png/customer-zone/consumer-safty-checklist</a>
//                         </li>
//                       </ul>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>

//               <div
//                 style="width:auto; border: 2px solid #52b049; border-radius: 6px; margin-top:4px; padding:10px; background: #e6f3e3;-webkit-print-color-adjust: exact; font-size:11px">
//                 <strong>Dear Consumer,</strong>
//                 <p style="text-align:justify; margin:5px 0">Invoice is raised once in two months on the basis of
//                   meter reading taken by MGL meter reader or meter Reading forwarded by yourself.
//                 </p>
//                 <p style="text-align:justify; margin:5px 0">We allow a Credit Period of 20 days to enable you to
//                   make payment. If you do not pay your bill by Due Date, Late Payment Charges of
//                   Rs. 100/- shall be levied. Continued default in making payment will attract
//                   additional Delayed Payment Charges@ 18% p.a. on unpaid value (beyond 9 days of
//                   delay) w.e.f. Due Date.</p>
//                 <p style="text-align:justify; margin:5px 0">In the event of continued default, MGL reserves the
//                   right to disconnect the supply. Reconnection shall be done only upon payment of
//                   Outstanding, (Including Late Payment charges, interest on delayed payment),
//                   disconnection &amp; reconnection charges.</p>
//                 <p style="text-align:justify; margin:5px 0">Any dispute in invoice should be brought to our notice
//                   within 14 days of the Bill Date.</p>
//                 <p style="text-align:justify; margin:5px 0">If you do not consume PNG equivalent to Rs.100/- in a
//                   Bimonthly Billing Period, the shortfall amount will be levied in addition to the
//                   Gas Consumption Charges.</p>
//                 <p style="text-align:justify;margin:5px 0">We forward the copy of the Bill by SMS as well as
//                   e-mail on demand. </p>
//                 <p style="text-align:justify;margin:5px 0">Piped Natural Gas (PNG) attracts MVAT and all other
//                   sale and supply of Goods and Services attracts GST.</p>
//               </div>

//             </div>
//             <div style="width: 49.5%">
//               <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                 style="border:2px solid #52b049; border-radius: 6px; margin-top: 0px">
//                 <tbody>
//                   <tr>
//                     <td height="25" align="center"
//                       style="background:#52b049; color:#FFF; font-size: 12px; font-weight: 600;-webkit-print-color-adjust: exact;">
//                       Customer Care</td>
//                   </tr>
//                   <tr>
//                     <td align="center" style="padding: 10px; background: #e6f3e3;-webkit-print-color-adjust: exact;">
//                       <div style="display: flex; align-items: center; justify-content: center;">
//                         <div style="margin-right: 10px">
//                           <h5 style="font-size:20px; margin: 0 0 4px ">(022)-68674500 <br>(022)-61564500</h5>
//                         </div>
//                         <img src="../../../assets/images/telephone.png" width="45">
//                       </div>
//             <div class="row d-flex justify-content-center whatsApp-number">
//               <div class="col-md-12">
//                 <h5 style="font-size:20px; margin: 0 0 5px; display: flex;align-items: center;justify-content: center;">WhatsApp- <img src="../../../assets/images/whats-app-icon-color.png" width="25"> 9899203843</h5>
//                 <h6  style="font-size: 20px;line-height:18px;color: #000; font-weight: 400; text-align: center; padding:0px; margin:0px">(Available 24X7)</h6>
//               </div>
//             </div>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//               <div
//                 style="border:2px solid #52b049; border-radius: 6px; margin-top: 4px; background: #e6f3e3;-webkit-print-color-adjust: exact;">
//                 <p style=" border-bottom:2px solid #52b049; font-size: 12px; padding-bottom: 5px;
// "><strong>Your Nearest Walk in center (10:00 AM to 5:00 PM, Monday to Saturday except Public & Bank Holidays)</strong>
//                 </p>
//                 <div style="display: flex; height:auto; align-items: center;">
//                   <p style="font-size: 10px;"><strong>${this.walkincenterData}</p>
//                 </div>
//               </div>
//               <table width="100%" border="0" cellspacing="0" cellpadding="0"
//                 style="border:2px solid #52b049; border-radius: 6px; margin-top: 4px; font-weight:normal">
//                 <tbody>
//                   <tr>
//                     <td height="25" align="center"
//                       style="background:#52b049; color:#FFF; font-size: 10px; font-weight: 600;-webkit-print-color-adjust: exact;">
//                       For Unresolved Issues Please Contact</td>
//                   </tr>
//                   <tr>
//                     <td style="padding:5px 10px; background: #e6f3e3;-webkit-print-color-adjust: exact; font-size:11px">
//                       <p style="margin:4px 0"><strong>Nodal Office:</strong><br> Mahanagar Gas Limited, Pay and Accounts
//                         Building,
//                         Ground Floor, Near Family Court, Bandra Kurla Complex, Bandra (East), Mumbai-400
//                         051. <br><strong>E-mail ID:</strong>
//                         <em>support@mahanagargas.com</em><br><strong>Telephone Number: 022-61564555</strong>
//                       </p>

//                       <p style="margin:0 0 3px 0"><strong>E-mail ID:</strong> support@mahanagargas.com</p>
//                       <p style="margin:0 0 3px 0"><strong>Telephone Number: 022-61564555</strong> </p>

//                       <p style="margin:0 0 3px 0"><strong>Complaint in-charge:</strong> Mr. Karan Mhole - Ext. No. 4549
//                       </p>
//                       <p style="margin:0 0 3px 0"><strong>Nodal Officer:</strong> Mr. Vikas Sarmalkar - Ext. No. 4548
//                       </p>
//                       <p style="margin:0 0 3px 0"><strong>Appellate Authority:</strong> Mr. Paresh Chanpur - Ext. No.
//                         4535</p>
//                       <p style="margin:0 0">You may contact the above-mentioned officials between 3:00 pm to 5:00 pm from Monday to Friday (Except Public & Bank Holidays) with the Unique Docket number given at the time of registering your complaint.
//                       </p>

//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//               <div
//                 style="border:2px solid #52b049; border-radius: 6px; margin-top: 4px; background: #e6f3e3; padding: 10px;-webkit-print-color-adjust: exact;">
//                 <img src="../../../assets/images/current-bill-MGL_Connect_App_Section.jpg" width="60%">
//               </div>
//               <div style="border:2px solid #52b049; border-radius: 6px; margin-top: 4px; background: #e6f3e3; padding:10px 10px; -webkit-print-color-adjust: exact;">
//                 <div style="display: flex; justify-content: space-between;">
//                 <div style="width: 50%; padding:10px 10px">
//                 <h3 style="margin:0 0 5px; font-size:12px">For T&C Please scan below QR</h3>
//                 <img src="${this.qrdata_tc}" style="width: 100px;" />
//                 </div>
//                 <div style="width: 50%; padding:10px 10px">
//                 <h3 style="margin:0 0 5px; font-size:12px">Generate your own bill</h3>
//                 <img src="../../../assets/images/customer-login-qr-code.png" width="100" />
//                  </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </td>
//       </tr>
//       <tr>
//         <td align="center">
//           <table width="100%" border="0" cellspacing="0" cellpadding="0"
//             style="border:2px solid #52b049; border-radius: 6px; margin-top: 4px">
//             <tbody>
//               <tr>
//                 <td height="25" align="center"
//                   style="background:#52b049; color:#FFF; font-size: 10px; font-weight: 600; -webkit-print-color-adjust: exact;">
//                   Where to Pay Your Gas Bill</td>
//               </tr>
//               <tr>
//                 <td>
//                   <table cellspacing="0" cellpadding="2" border="0" align="center" style="font-size: 11px;">
//                     <tbody>
//                       <tr>
//                         <td width="20.3290246769%" align="center" bgcolor="#e6f3e3" class="heading"
//                           style="border:1px solid #52B049; border-width:0 1px 1px 0; font-size: 12px;-webkit-print-color-adjust: exact;">
//                           <strong>Visit Our Website</strong></td>
//                         <td width="19.2714453584%" align="center" bgcolor="#e6f3e3" class="heading"
//                           style="border:1px solid #52B049; border-width:0 1px 1px 0;font-size: 12px;-webkit-print-color-adjust: exact;">
//                           <strong>Across the Counter</strong></td>
//                         <td width="38.0728554642%" colspan="3" align="center" bgcolor="#e6f3e3" class="heading"
//                           style="border:1px solid #52B049; border-width:0 1px 1px 0; font-size: 12px;-webkit-print-color-adjust: exact;">
//                           <strong>YOU CAN PAY BY RTGS/NEFT
//                           </strong></td>
//                         <td width="20.7990599295%" align="center" bgcolor="#e6f3e3" class="heading"
//                           style="border:1px solid #52B049; border-width:0 0 1px 0;font-size: 12px;-webkit-print-color-adjust: exact;">
//                           <strong>E-NACH</strong></td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52B049; border-width:0 1px 0 0" rowspan="6">
//                           <strong>Online Payments</strong>
//                           <p style="padding:0 0 3px 0">Pay By Net Banking, Cards, UPI</p>
//                           <strong>Post office locations:</strong>
//                           <p style="padding:0 0 3px 0">Mumbai, Mira Road, Thane</p>
//                           <strong>Drop Box:</strong>
//                           <p style="padding:0 0 3px 0">ICICI Bank, Axis Bank</p>
//                           <strong>UPI</strong>
//                           <p style="padding:0 0 3px 0">HSBC, Paytm, Bill Desk</p>
//                           <!-- <strong>NACH/E-NACH</strong>
//                                                     <p style="padding:0 0 3px 0">For Registration &amp; Cancellation</p>-->
//                         </td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0" rowspan="2" align="center">
//                           Operators : Outlets of Suvidhaa, Paypoint,
//                           Euronet.</td>
//                         <td width="95px"
//                           style="white-space: nowrap; border:1px solid #52B049; border-width:0 1px 1px 0"><strong>Bank
//                             Name</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0" width="122px"><strong>YES Bank
//                             Ltd.</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0" width="120px"><strong>State Bank
//                             of India</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 0 1px 0" rowspan="3" align="center">Please avail 1%
//                           Discount on Gas Consumption Charges subject to
//                           maximum of ₹ 50/-</td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong
//                             style="white-space: nowrap">Account no.</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong
//                             id="ContentPlaceHolder1_lblacno">MGL999 ${this.billData.contract_acc}</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong
//                             id="ContentPlaceHolder1_lblacno1">MGL1 ${this.billData.contract_acc} </strong></td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0" rowspan="2"></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong
//                             style="white-space: nowrap">IFSC</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong>YESB0CMSNOC</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong>SBIN0004266</strong></td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong
//                             style="white-space: nowrap">Type of Account</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong>Current Account</strong>
//                         </td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 1px 0"><strong>Current Account</strong>
//                         </td>
//                         <td style="border:1px solid #52B049; border-width:0 0 1px 0" bgcolor="#e6f3e3" class="heading">
//                           RTGS/NEFT</td>
//                       </tr>
//                       <tr>
//                         <td align="center" bgcolor="#e6f3e3" class="heading"
//                           style="border:1px solid #52B049; border-width:0 1px 1px 0;-webkit-print-color-adjust: exact;">
//                           UBIQC</td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 0 0" rowspan="2"><strong
//                             style="white-space: nowrap">Branch Add.:</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 0 0" rowspan="2"><strong>Unit No.2-A 1, Ground Floor, Hallmark Business Plaza, Gurunanak Hospital Road, Kalanagar, Bandra East 400051</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 1px 0 0" rowspan="2"><strong>Cash Management Product Operations Centre, State Bank of India "AISHWARYAM" Survey No. 26, Lingampally, Hyderbad -500019</strong></td>
//                         <td style="border:1px solid #52B049; border-width:0 0 0 0" rowspan="2" align="center">Please transfer directly
//                           from your bank A/C to MGL Bank</td>
//                       </tr>
//                       <tr>
//                         <td style="border:1px solid #52B049; border-width:0 1px 0 0">View And Pay MGL Bills Download App
//                           From Play Store</td>
//                       </tr>
//                     </tbody>
//                   </table>
                  
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//           <div style="border:2px solid #52b049; margin-top:5px;border-radius:6px;display: flex;"><img src="../../../assets/images/current-bill-ad-new2.jpg" width="100%" style="border-radius:4px;"></div>
//         </td>
//       </tr>
//     </tbody>
//   </table>

// </body>

// </html>
//     `

//     // PDF.addImage(Applications, 'JPEG', 0, position, 1000, 1000);
//     // PDF.save('angular-demo.pdf');
//     // PDF.html

//     var popupWin = window.open('', '_blank', 'width=1000,height=600');
//     popupWin!.document.open();
//     popupWin!.document.write(Applications);

//     setTimeout(function () {
//       popupWin?.print()
//       popupWin!.document.close();
//     }, 500);
//   }


printPdf() {

  if(this.uptodueAmt==undefined){
    this.uptodueAmt="";
  }

const canvas: any = document.getElementById('canvas');
let canvasImg = canvas.toDataURL('image/png');
//console.log(canvasImg)
// const box = document.getElementById(
//   'canvasdivid',
// ) as HTMLDivElement | null;
// console.log(box?.innerHTML);
  //this.loadChart1();

  //this.chart.destroy();

  // this.loadChart();

    // var date = this.datePipe.transform(this.UserForm2.get('DOB')?.value, 'dd-MM-yyyy')
    // var currDate = this.datePipe.transform(new Date(), 'dd MMM, yyyy')
    const strEmail = this.email ?this.email : '';
    var Applications: any = `
   <!doctype html>
<html>
<head>
<meta charset="utf-8">
<title>Untitled Document</title>
</head>

<body>
<div style="margin:0 auto; width:820px; font-family:Arial; font-size:12px; border-bottom:5px solid #fbc78c; position:relative;">
<div style="margin-bottom:0px">
<table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td valign="top" width="65%">
        <div style="display:flex; border:2px solid #6877b4; padding:4px 10px; border-radius: 8px;">
  <a href="#"><img src="../../../assets/images/currbillimgnew/logo.png" height="70px"></a>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center" style="font-size:12px">Tax Invoice</td>
    </tr>
    <tr>
      <td align="center" style="font-size:20px; color:#0069B3; font-weight:bold">MAHANAGAR GAS LIMITED</td>
    </tr>
    <tr>
      <td align="center" style="font-size:12px; color:#0F9400">An ISO 9001, 14001 and 45001 Certified Company</td>
    </tr>
    <tr>
      <td align="center" style="font-size:12px;color:#0069B3;">www.mahanagargas.com</td>
    </tr>
  </tbody>
</table>

  </div>
    </td>
      <td>&nbsp;</td>
      <td width="35%">
      <table width="100%" border="0" cellspacing="0" cellpadding="5" style="font-size: 12px; font-weight:600">
        <tbody>
          <tr>
            <td style="background:#c5e5ee; padding: 6px 0 6px 10px;border-bottom:2px solid #FFF; border-radius:8px 0 0 0;-webkit-print-color-adjust: exact; ">CA Number </td>
            <td style="background:#c5e5ee; padding: 6px 0 6px 10px;border-bottom:2px solid #FFF; border-radius:0 8px 0 0;-webkit-print-color-adjust: exact;">: ${this.billData.contract_acc}</td>
          </tr>
          <tr>
            <td style="background: #b6d7a4;padding:6px 0 6px 10px;border-bottom:2px solid #FFF;-webkit-print-color-adjust: exact; text-transform: uppercase;">Bill Date </td>
            <td style="background: #b6d7a4;padding: 6px 0 6px 10px;border-bottom:2px solid #FFF;-webkit-print-color-adjust: exact;">: ${this.billData.bill_date}</td>
          </tr>
          <tr>
            <td style="background: #fbc78c;padding:6px 0 6px 10px; border-radius:0 0 0 8px;-webkit-print-color-adjust: exact;text-transform: uppercase">Period</td>
            <td style="background: #fbc78c;padding:6px 0 6px 10px; border-radius:0 0 8px 0;-webkit-print-color-adjust: exact;">: ${this.previousDate} to ${this.closingDate}</td>
          </tr>
          
        </tbody>
      </table>
    </td>
    </tr>
    <tr>
      <td valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="margin:0 0 0 0">
        <tbody>
          <tr>
            <td valign="top" width="25%"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #f190a0; border-radius:6px; padding:2px; ">
              <tbody>
                <tr>
                  <td align="center"><h2 style="margin:0 0; font-size: 10px">Emergency</h2></td>
                </tr>
                <tr>
                  <td align="center">
                    <p style="margin:2px 0; font-size:9px;">(Gas Leak, Gas Stop, Fire)</p>
                    <h5 style="color:#e80717; font-size: 10px; margin: 5px 0">18002669944 (Tollfree)<br>
                      (022)-68759400,<br>
                      (022)-24012400</h5>
                      <p style="margin: 0px 0 0;font-size: 10px;justify-content: center;display: flex;" ><img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="10" style="margin:0px 2px 0">9899203843</p>

                    <p style="margin: 5px 0 0;font-size: 10px;">(Available 24X7)</p></td>
                </tr>
              </tbody>
            </table></td>
            <td width="10">&nbsp;</td>
            <td valign="top" width="75%"><div style="border:2px solid #6877b4; border-radius: 6px; padding:5px 10px; min-height:84px">
              <h2 style="font-size: 11px; line-height: 20px;color: #000; text-transform: uppercase; padding: 0 2px 0 4px; margin: 0;">${this.billData.name}</h2>
              <p style="font-size: 10px; line-height: 17px;  color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">${this.details}
                </p>
              <p style="font-size: 10px; line-height: 17px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;"> </p>
              <p style="font-size: 10px; line-height: 17px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">${this.landmark} ${this.city}</p>
                <p style="font-size: 10px; line-height: 17px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;"> </p>
              <p style="font-size: 10px; line-height: 17px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">Mobile No: ${this.mobile}, Email Id:${strEmail}</p>
              <p style="font-size: 10px; line-height: 17px; color: #000; text-transform: none; padding: 0 2px 0 4px; margin: 0;">BP No:${this.billData.partner_no}</p>
            </div></td>
          </tr>
        </tbody>
      </table></td>
      <td>&nbsp;</td>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td>
        <div style="margin:0 auto; width: 70px; height: 70px; background:#FBC78C; border-radius:70px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact;">
        <h2 style="margin:0 0 2px; font-weight: 800;font-size: 11px; text-align:center ">${this.uptoTotal}</h2>
        <span style="font-size:10px">Amount upto</span>
        </div>
        <div style="background:#B6D7A4;font-weight: 800; width: 75%; margin:-8px auto 0; border-radius: 40px;font-size: 12px; padding: 10px 5px 7px; text-align: center;-webkit-print-color-adjust: exact">${this.dueDate}</div>
        </td>
            <td>&nbsp;</td>
            <td>
        <div style="margin:0 auto; width: 70px; height: 70px; background:#FBC78C; border-radius:70px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
        <h2 style="margin:0 0 2px; font-weight: 800;font-size: 11px; text-align:center">${this.uptodueAmt}</h2>
        <span style="font-size:10px">Amount after</span>
        </div>
        <div style="background:#B6D7A4;font-weight: 800; width:75%; margin:-8px auto 0; border-radius: 40px;font-size: 12px; padding: 10px 5px 7px; text-align: center;-webkit-print-color-adjust: exact">${this.dueDate}</div>
        </td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>
</div>  
  <div style="margin-bottom:0px">
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="49%" align="left" valign="top">
    <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td>
    <table width="100%" border="0" cellspacing="0" cellpadding="3" style="border:2px solid #6877b4; border-radius: 6px; ">
                    <tbody>
                      <tr>
              <td colspan="3"  style="background:#c5e5ee;-webkit-print-color-adjust: exact; border-radius:4px 0 4px 0;">
                <div style="display: flex;justify-content: space-between;">
                <span>MVAT Invoice No : </span>
                <span><strong> ${this.billData.TAX_INVNO} </strong></span>
                </div>
              </td>
              </tr>
                      
                      <tr>
                        <td style="background:#fee8d4;-webkit-print-color-adjust: exact;"><strong>SALE OF NATURAL GAS</strong></td>
                        <td style="border-right:1px solid #6877b4; background:#fee8d4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" style="background:#fee8d4;-webkit-print-color-adjust: exact; padding-right: 20px"><strong><strong></strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong> Gas Consumption SCM (Standard Cubic Meter.)<strong></strong></strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;  ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.billData.cur_cons}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>Gas Consumption Charges Rate Per<strong> SCM (Domestic)${this.billData.rate}</strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.billData.cons}</strong></strong></td>
                      </tr>
            <tr>
                        <td bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;"><strong>MVAT @3% <strong></strong></strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.billData.vat}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>Arrears<strong></strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.str_arrears_emi}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;"><strong>Credit Balance / Discount / Rebate</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0"  style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.v_creditbalance}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FDDDBC"  style="-webkit-print-color-adjust: exact;"><strong>TOTAL CHARGES A</strong></td>
                        <td bgcolor="#FDDDBC" style="border-right:1px solid #6877b4; -webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact; padding-right: 20px"><strong><strong>${this.totalPartA}</strong></strong></td>
                      </tr>
                       <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>GST Invoice No. ${this.billData.bill_no}</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px"></td>
                      </tr>
                     
            <tr>
                        <td bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;"><strong>Other Charges</strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact; ">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;padding-right: 20px">${this.otherCharges.toFixed(2)}</td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>Minimum Charges</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px">${this.billData.min_chg}</td>
                      </tr>
                      <tr>
                        <td bgcolor="#ECF4E2"  style="-webkit-print-color-adjust: exact;"><strong>SGST @9%<strong></strong></strong></td>
                        <td bgcolor="#ECF4E2" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#ECF4E2" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.v_SGST.toFixed(2)}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;"><strong>CGST @9%</strong></td>
                        <td bgcolor="#F5F9F0" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#F5F9F0" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong><strong>${this.v_CGST.toFixed(2)}</strong></strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact;"><strong>TOTAL CHARGES B</strong></td>
                        <td bgcolor="#FDDDBC" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FDDDBC" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong>${this.vPartB.toFixed(2)}</strong></td>
                      </tr>
                      <tr>
                        <td bgcolor="#FBC78C" style="-webkit-print-color-adjust: exact;"><strong>TOTAL PAYABLE (A+B)<strong></strong></strong></td>
                        <td bgcolor="#FBC78C" style="border-right:1px solid #6877b4;-webkit-print-color-adjust: exact;">&nbsp;</td>
                        <td align="right" bgcolor="#FBC78C" style="-webkit-print-color-adjust: exact;padding-right: 20px"><strong>${this.uptoTotal}</strong></td>
                      </tr>
                      
                      
              </tbody>
                  </table>
    </td>
    </tr>
    <tr>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="3" style="border-radius: 4px; border:2px solid #6877b4;">
        <tbody>
          <tr>
            <td style="border-bottom: 2px solid #6877b4"><strong>Gas Consumption Security Deposit Rs.${this.billData.EMI_CLEARED}</strong></td>
          </tr>
          <tr>
            <td>${this.str5000}</td>
          </tr>
         
        </tbody>
      </table></td>
    </tr>
    <tr>
      <td><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td>
        <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
        <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">Meter No.</h2>
        
        </div>
        <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">${this.billData.meter_no}</div></td>
            <td>&nbsp;</td>
            <td>
        <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
          <span style="font-size:8px">Previous Reading </span>
        <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">${this.previoseReading}</h2>
        <span style="font-size:11px">${this.assessedActual}</span>
         <span style="font-size:8px">Date</span>
        </div>
        <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">${this.previousDate}</div>
        </td>
            <td>&nbsp;</td>
            <td> <div style="margin:0 auto; width: 80px; height: 80px; background:#FBC78C; border-radius:80px; display: flex; flex-direction: column;justify-content: center; align-items: center; border:2px solid #FFF; position: relative;z-index: 99;-webkit-print-color-adjust: exact">
          <span style="font-size:8px">Closing Reading</span>
        <h2 style="margin:0 0 2px; font-weight: 800; font-size:12px">${this.closingReading}</h2>
        <span style="font-size:11px">${this.closingActual}</span>
         <span style="font-size:8px">Date</span>
        </div>
        <div style="background:#B6D7A4;font-weight: 800; width: 80%; margin:-8px auto 0; border-radius: 40px;font-size: 11px; padding:6px 5px 4px; text-align: center;-webkit-print-color-adjust: exact">${this.closingDate}</div></td>
          </tr>
        </tbody>
      </table></td>
    </tr>
    <tr>
        <td>
         <div id="canvasdivid">
         <img src="${canvasImg}" height="130px" width="100%">
         </div>
        </td>
    
    </tr>
  </tbody>
</table>

    </td>
      
      <td width="49%" align="left" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="2">
        <tbody>
          <tr>
            <td><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid  #6877b4; border-radius: 6px; height:364px">
              <tbody>
                <tr>
                  <td height="25" align="left" style="background:#c5e5ee; color:#000; font-size: 12px; -webkit-print-color-adjust: exact; padding-left:15px; font-weight:800">IMPORTANT INFORMATION</td>
                </tr>
                <tr>
                  <td style="padding:5px 10px; position: relative;vertical-align: top;">
                  
                    <p style="font-size:11px; margin:0 0 3px">${this.important1} </p>
          
          <p style="font-size:11px; margin:0 0 3px">${this.importantNew ? this.importantNew:''}</p>
            <p style="font-size:11px; margin:0 0 3px">${this.importantNew1 ? this.importantNew1:''}</p>
            
           
            <div style="position:absolute; left: 50%; top:50%; transform: translate(-50%,-50%); opacity:0.1; z-index: -1 ">
            <img src="../../../assets/images/currbillimgnew/mg-icon.png" style="width: 100%">
            </div>
          </td>
                </tr>
              </tbody>
            </table></td>
          </tr>
          
          <tr>
            <td>
        <table width="100%" border="0" cellspacing="0" cellpadding="5" style="border:2px dashed #6877b4; border-radius: 4px; margin-top: 5px;min-height:100px">
        <tbody>
         <tr>
           ${this.mtrImg}
           <!-- <td width="51%" align="center">
        
          </td> -->
          <td width="49%" align="center"><strong>${this.meterImgTxt}</strong></td>
        </tr>
        </tbody>
      </table>
        </td>
          </tr>
          
          <tr>
            <td>
        <div style="width:auto; border: 2px solid #6877b4; border-radius: 6px; margin-top:8px;">
          <p style="background:#c5e5ee; color:#000; font-size: 12px; padding: 5px 5px 5px 15px; margin: 0;-webkit-print-color-adjust: exact;font-weight:800;">Declaration</p>
          <div style="padding:8px">
          <p style="margin: 0 0 5px;font-size:8px;"><strong>It is hereby certified that Registration Certificate of the Company issued under Maharashtra Value Added Tax Act, 2002 is in force as on date and that the transaction of sale covered under this Tax Invoice shall be accounted for in the Turnover of Sales while filing of Returns and tax payable on the sale, if any, has been paid or shall be paid.</strong></p>
            <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td><p style="font-size: 9px; font-weight:600; margin: 0"><strong>For Mahanagar Gas Ltd</strong></p>
            <img src="../../../assets/images/currbillimgnew/chief-manager-sign1.jpg" style="height:42px">
          <p style="font-size: 8px; margin:0">Chief Manager - Revenue & Taxation</p></td>
      <td align="right"> <img src="../../../assets/images/currbillimgnew/stamp.jpg" width="80"></td>
    </tr>
  </tbody>
</table>

          
            </div>
        </div>
        </td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table> 

  </div>
    <div><img src="../../../assets/images/currbillimgnew/caution-ad13-11.jpg" style="width:100%; border-radius:8px; margin:0 0 2px;border: 2px solid #6877b4; border-radius: 6px;"></div>
    <div style="border-radius: 6px;margin-bottom: 5px; height:auto; border:2px solid #6877b4; padding:10px 10px; display:none">
      <p style="font-size: 11px; margin: 0;-webkit-print-color-adjust: exact;">We are excited to present our PNG bill in a new format. We at MGL, constantly endeavour to provide unmatched convenience while maintaining simplicity. </p>
<p style="font-size: 11px; margin: 0;">
This changed format will provide you with a cleaner presentation of your account & past consumption along with multiple and easy payment options.
</p>
<p style="font-size: 11px; margin: 0;">One more important detail about the new format. A young student and budding artist Akash Sthool from Sir J. J. Institute of Applied Arts has designed the format.</p>
<p  style="font-size: 11px; margin: 0; display:flex">So do tell us about the change as well as our services at support @ mahanagargas.com or <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="15" style="margin:0 5px"> 9899 20 3843</p>
<p  style="font-size: 11px; margin:-3px 0 0 0;">Thanking you once again for the opportunity to serve you.</p>
<p style="font-size: 11px; margin: 0; text-align:right; font-weight:700">Team MGL</p>
      
    </div>

  <div style="margin-bottom:0px; border-top:2px dashed #000;padding:5px 0 0">
    <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td width="60%" valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px; font-size:11px">
                    <tbody>
                      <tr>
                        <td height="20" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 11px; font-weight:800">
                          PAYMENT SLIP/CHEQUE
                        </td>
                      </tr>
                      <tr>
                        <td >
              <table width="100%" border="0" cellspacing="0" cellpadding="3">
  <tbody>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 2px 2px 0"><strong>Cheque No:</strong></td>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong> Cheque Dt:</strong></td>
    </tr>
    <tr>
      <td colspan="2" style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong>Bank Name/ branch:</strong></td>
      </tr>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 2px 2px 0"><strong>CA No:${this.billData.contract_acc}</strong></td>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0"><strong>Due date:${this.billData.due_date}</strong></td>
    </tr>
    <tr>
      <td style="border: 2px solid #6877b4; border-width: 0 0 2px 0" colspan="2"><strong>Amount Paid: ${this.uptoTotal}</strong></td>
      </tr>
    <tr>
      <td colspan="2" style="font-size:10px">Please pay by cheque in favour of Mahanagar Gas Ltd CA No. 2100 0000 7924</td>
      </tr>
  </tbody>
</table>

              </td>
                      </tr>
                    </tbody>
                  </table>
    
    </td>
      <td>&nbsp;</td>
      <td width="40%" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px">
        <tbody>
          <tr>
            <td height="20" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight:800"> Pay through QR (Quick Response) Code </td>
          </tr>
          <tr>
            <td ><table width="100%" border="0" cellspacing="0" cellpadding="4" style="height:96px">
              <tbody>
                <tr>
                  <td width="50%" align="center" valign="middle">
                  <div style="display:flex; align-items: center;justify-content: space-around;">
                  <img src="${this.qrdata_upi}" height="90">
                  <img src="../../../assets/images/currbillimgnew/upi-logo.jpg" style="height:27px;">
                  </div>
                  </td>
                 <!--  <td width="50%" align="center" valign="middle">
                  <div style="display:flex; align-items: center; justify-content: space-around;">
                  <img src="${this.qrdata_paytm}" height="75" >
                  <img src="../../../assets/images/currbillimgnew/paytm-logo.jpg" style="height:27px;">
                  </div>
                  </td>-->
                </tr>
              </tbody>
            </table></td>
          </tr>
        </tbody>
      </table></td>
    </tr>
  </tbody>
</table>

  </div>
  <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center" style="font-size: 14px; font-weight: 800">Customer Care - 24x7</td>
    </tr>
    <tr>
      <td align="center" style="display:flex; font-size: 12px;justify-content: center;align-items: center;"><img src="../../../assets/images/currbillimgnew/call-img.jpg" width="16" style="margin:0 10px">(022) 6867 4500 &amp; 6156 4500 <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="16" style="margin:0 10px">9899 20 3843 </td> 
    </tr>
    <tr>
      <td align="center">
      <h4 style="background:#c5e5ee; padding:6px 0; color:#000; font-size:11px; text-align: center; margin:5px 0;-webkit-print-color-adjust: exact; font-weight: normal">
        CIN #L40200MH1995PLC088133 | VAT # 27390000364V | C.S.T #27390000364C | GSTIN :27AABCM4640G1ZN |PLACE OF SUPPLY : MAHARASHTRA (27)</h4></td>
    </tr>
  </tbody>
</table>
<div style="page-break-before:always"></div> 
<div style="position:absolute; left:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
  <div style="position:absolute; right:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
</div>
<div style="margin:0 auto; width:750px; font-family:Arial; font-size:12px; border-bottom:5px solid #fbc78c; padding: 10px 30px; position: relative">
  <div style="position:absolute; left:0; top: 0; z-index: -1;transform: rotateX(180deg);"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
  <div style="position:absolute; right:0; top: 0; z-index: -1;transform: rotateX(180deg);"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
  <p style="margin-bottom:3px; text-align: center">Subject To Mumbai Juisdiction</p>
  <table width="100%" border="0" cellspacing="0" cellpadding="2">
    <tbody>
      <tr>
        <td width="64%"><img src="../../../assets/images/currbillimgnew/if-you-smell1.png" style="width: 100%"></td>
        <td>&nbsp;</td>
        <td valign="top">
        <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #6877b4; border-radius: 6px; margin: 30px 0 0 0">
                    <tbody>
                      <tr>
                        <td height="25" align="center" style="background:#c5e5ee; -webkit-print-color-adjust: exact; color:#000; font-size: 12px; font-weight: 800">
                          Best Safety Practices
                        </td>
                      </tr>
                      <tr>
                        <td>
              <ul style="padding:0 10px 0 25px; font-size: 11px; line-height: 15px;">
      <li style="margin-bottom:5px">Ensure Appliance Valve / Meter Control Valve is closed when not using Gas.</li>
          <li style="margin-bottom:5px">Keep windows open and allow air circulation inside Kitchen to facilitate ventilation</li>
          <li style="margin-bottom:5px">Electric installation / Wiring should never touch or traverse Gas line. Please maintain a clear distance of at least 01 foot (300mm).</li>
          <li style="margin-bottom:5px"> Do not conceal Gas Pipes in permanent or temporary structures.</li>
          <li > Gas Geyser must not be installed inside bathrooms or closed areas.</li>
      </ul>

              </td>
                      </tr>
                    </tbody>
                  </table>
        
      </td>
        </tr>
      </tbody>
  </table>
  <div style="margin:0 0 0">
  <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="50%" valign="top">
      <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td><div style="width:100%;text-align:center;border: 2px solid #6877b4; border-radius: 6px;">
       <img src="../../../assets/images/currbillimgnew/mgl-tez-2page.png" style="width:100%;border-radius: 6px;">
      </div></td>
    </tr>
    <tr>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <td>
    <div style="border:2px solid #6877b4; border-radius:4px; min-height: 140px; position: relative">
      <h2 style="margin:8px 5px 5px 85px; position: relative">Dear Consumer, 
        <i style="position: absolute; left:-60px; top:-28px; border:2px solid #24388e; width: 50px; height: 50px; display: flex; border-radius: 50px; background: #FFF;align-items: center;
    justify-content: center;"><img src="../../../assets/images/currbillimgnew/user-icon.jpg"></i>
      </h2>
      <ul  style="padding:0 5px 0 25px">
      <li  style="margin-bottom:6px">Invoice is raised once in two months on the basis of meter reading taken by MGL meter reader or meter Reading forwarded by yourself.</li>
        <li  style="margin-bottom:6px">We allow a Credit Period of 20 days to enable you to make payment. If you do not pay your bill by Due Date, Late Payment Charges of Rs. 100/- shall be levied.</li>
        <li  style="margin-bottom:6px"> Continued default in making payment will attract additional Delayed Payment Charges@18% p.a. on unpaid value (beyond 9 days of delay) w.e.f. Due Date.</li>
        <li  style="margin-bottom:6px">In the event of continued default, MGL reserves the right to disconnect the supply. Reconnection shall be done only upon payment of Outstanding, (Including Late
Payment charges, interest on delayed payment), disconnection & reconnection charges.</li>
        <li  style="margin-bottom:6px">Any dispute in invoice should be brought to our notice within 14 days of the Bill Date.</li>
        <li  style="margin-bottom:6px">If you do not consume PNG equivalent to Rs. 100/- in a Bimonthly Billing Period, the shortfall amount will be levied in addition to the Gas Consumption Charges.</li>
        <li  style="margin-bottom:6px">We forward the copy of the Bill by SMS as well as e-mail on demand.</li>
        <li  style="margin-bottom:6px">Piped Natural Gas (PNG) attracts MVAT and all other sale and supply of Goods and Services attract GST.</li>
         <li>PNG (Domestic) price break-up is available on our website.</li>
      </ul>
      <div style="position:absolute; left: 50%; top:50%; transform: translate(-50%,-50%); opacity:0.1; z-index: -1; width: 90% ">
            <img src="../../../assets/images/currbillimgnew/mg-icon.png" style="width: 100%">
            </div>
      </div>
    </td>
    </tr>
  </tbody>
</table>
</td>
      <td width="5"></td>
      <td width="49%" align="left" valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td>
    <div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight: 800;">Your Nearest Walk in center (09:30 AM to 5:30 PM)  <span style="font-size: 11px; font-weight:normal;">Monday to Saturday except Public &amp; Bank Holidays</span></p>
          <div style="padding:5px 10px">
          <p style="margin:0 0 2px 0; line-height:16px">${this.walkincenterData}</p>
            
          
            </div>
        </div>
    </td>
    </tr>
    <tr>
      <td><div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight:800;">For Unresolved Issues, Please Contact</p>
        <div style="padding:5px 10px">
          <p style="margin:0 0 5px 0">You may contact the officials between 3:00 pm to 5:00 pm from Monday to Friday (Except Public & Bank Holidays) with the Unique Docket number given at the time of registering your complaint.</p>
          <p  style="margin:0 0 5px 0"><strong>Nodal Office:</strong><br> Mahanagar Gas Ltd, Pay & Accounts Building, Ground Floor, Near Family Court, Bandra Kurla Complex, Bandra (East), Mumbai-400051.</p>
          <p  style="margin:0 0 5px 0"><strong style="display: inline-block; width: 120px;">Telephone Number :</strong> 022-6156 4555</p>
          <p  style="margin:0 0 5px 0"><strong style="display: inline-block; width: 120px;">Complaint in-charge:</strong> Mr. Karan Mhole - Ext. No. 4549</p>
          <p  style="margin:0 0 5px 0"><strong style="display: inline-block; width: 120px;">Nodal Officer :</strong> Mr. Vikas Sarmalkar - Ext. No. 4548</p>
      <p  style="margin:0 0 5px 0"><strong style="display: inline-block; width: 120px;">Appellate Authority :</strong> Mr. Paresh Chanpur - Ext. No. 4535</p>
      <p  style="margin:0 0 0"><strong style="display: inline-block; width: 120px;">Email ID :</strong>  support@mahanagargas.com</p>
        </div>
      </div></td>
    </tr>
    <tr>
      <td><div style="border:2px solid #6877b4; border-radius: 6px; ">
        <p style=" border-bottom:2px solid #6877b4; font-size: 12px; padding:4px 7px; background:#c5e5ee; text-align: center; margin: 0;-webkit-print-color-adjust: exact; font-weight: 800;">
         CONNECT <i>for</i> CONVENIENCE</p>
        <div style="padding:10px">
          <table width="100%" border="0" cellspacing="0" cellpadding="2">
  <tbody>
    <tr>
      <td width="48%" align="center">
      <p style="margin:0 0 5px 0;font-size:11px">MGL CONNECT APP</p>
      <img src="../../../assets/images/currbillimgnew/qrcode_mglapp.png" width="90">
      <p style="margin:5px 0 0 0">Scan the QR Code to download the MGL CONNECT APP</p>
    </td>
      <td>&nbsp;</td>
      <td width="50%" align="center">
      <p style="margin:0 0 5px 0; font-size:11px">GENERATE YOUR OWN BILL</p>
      <img src="../../../assets/images/currbillimgnew/generate-your-own-bill.jpg" width="90">
      <p style="margin:5px 0 0 0">Scan the QR Code to<br>
        GENERATE YOUR OWN BILL</p>
    </td>
    </tr>
  </tbody>
</table>

        </div>
      </div></td>
    </tr>
    
  </tbody>
</table>
</td>
    </tr>
  </tbody>
</table>

  </div>
  
  <div style="margin:0 0">
     <div style="background:#b6d7a4; color:#000; font-size: 13px; font-weight: 800; -webkit-print-color-adjust: exact; padding:3px 0; text-align:center;">Easy to Pay your Gas Bills here</div>
    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border:2px solid #0f70b7; border-radius: 6px; margin-top: 4px">
                    <tbody>
                      
                      <tr>
                        <td>
            <table cellspacing="0" cellpadding="6" border="0" align="center" style="font-size: 11px;">
                                        <tbody>
                                            <tr>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0; font-size: 10px;-webkit-print-color-adjust: exact; font-weight: 800; color:#0c4d7d;">Digital</td>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0;font-size: 10px;-webkit-print-color-adjust: exact; font-weight: 800;color:#0c4d7d;">Across the Counter</td>
                                                <td width="52%" colspan="3" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 1px 1px 0; font-size: 10px;-webkit-print-color-adjust: exact;font-weight: 800;color:#0c4d7d;">RTGS/NEFT
                                                </td>
                                                <td width="16%" align="center" bgcolor="#cde9f5" class="heading" style="border:1px solid #0f70b7; border-width:0 0 1px 0;font-size: 10px;-webkit-print-color-adjust: exact;font-weight: 800;color:#0c4d7d;">E-NACH</td>
                                            </tr>
                                            <tr>
                                                <td rowspan="5" valign="top" style="border:1px solid #0f70b7; border-width:0 1px 0 0">
                                                    <strong>Online Payments</strong>
                                                    <p style="font-size:9px">Pay By Net Banking, Cards, UPI, BillDesk <img src="../../../assets/images/currbillimgnew/whatsapp.jpg" width="11" style="margin-top:2px"></p>
                                                    
                                                </td>
                                                <td rowspan="5" align="left" valign="top" style="border:1px solid #0f70b7; border-width:0 1px 1px 0">
                          <strong>Post office location:</strong>Mumbai, Mira Road,<br>Thane <br><br>
                          <strong>Drop Box</strong>
                          ICICI Bank, Axis Bank <br><br>
                          Outlets of Suvidhaa, Paypoint, Euronet
                        </td>
                                                <td width="95" style="white-space: nowrap; border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Bank Name</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0" width="122"><strong>YES Bank Ltd.</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0" width="120"><strong>State Bank of India</strong></td>
                                                <td rowspan="5" valign="top" style="border:1px solid #0f70b7; border-width:0 0 1px 0">Please avail 1% Discount on Gas Consumption Charges subject to
                                                    maximum of Rs. 50/-</td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">Account No.</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0">MGL999 ${this.billData.contract_acc}</td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0">MGL1 ${this.billData.contract_acc}</td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">IFSC</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>YESB 0CM SNOC</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>SBIN 000 4266</strong></td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong style="white-space: nowrap">Type of Account</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Current Account</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 1px 0"><strong>Current Account</strong></td>
                                            </tr>
                                            <tr>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong style="white-space: nowrap">Branch:</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong>YES BANK, MUMBAI</strong></td>
                                                <td style="border:1px solid #0f70b7; border-width:0 1px 0 0"><strong>STATE BANK OF INDIA, MUMBAI</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                       
              </td>
                      </tr>
                    </tbody>
                  </table>
    <p style="margin:5px 0 0; text-align: center">Corporate & Registered Office: MGL House, G-33, Bandra Kurla Complex, Bandra(East), Mumbai-400 051</p>
  </div>
  <div style="position:absolute; left:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-left.jpg"></div>
  <div style="position:absolute; right:0; bottom: 0; z-index: -1"><img src="../../../assets/images/currbillimgnew/bottom-bg-right.jpg"></div>
  </div>
  </body>
</html> `

    // PDF.addImage(Applications, 'JPEG', 0, position, 1000, 1000);
    // PDF.save('angular-demo.pdf');
    // PDF.html
    //this.loadChart()

    var popupWin = window.open('', '_blank', 'width=1000,height=600');
    popupWin!.document.open();
    popupWin!.document.write(Applications);

    setTimeout(function () {
      popupWin?.print()
      popupWin!.document.close();
    }, 500);
  }

  toDataURL = async (url: any) => {
    //console.log("Downloading image...");
    var res = await fetch(url);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })

    return result
  };




}
