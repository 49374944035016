<div class="innerpage-banner">
    <img src="assets/images/innerpage-banner/about-banner.jpg">
    <div class="banner-heading">
        <h1>Investor <span>Guide</span></h1>
        <div class="breadcrumbs">
            <ul class="">
                <li class=""><a [routerLink]="['/']">Home</a></li>
                <li class=""><a >MGL Corporate</a></li>
                <li class=""><a >Investors</a></li>
                <li class="active" aria-current="page">Investor Guide</li>
            </ul>
        </div>
    </div>
</div>
<!-- Services Section Start -->
<!-- <app-service-section></app-service-section> -->
<app-mgl-corporate-service-section></app-mgl-corporate-service-section>
<!-- Services Section End -->

<!-- Inner Page Content Section Start -->
<div class="innerpage-content-section">
    <div class="container custom">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 order-lg-1 order-md-2 order-2">
                <app-recent-post></app-recent-post>

                <app-tips></app-tips>

                <div class="mt-30">
                    <app-news-comp></app-news-comp>
                    <!-- <div class="left-blog-section">
                        <div class="left-blog-posts">
                            <div class="recent-posts">
                                <div class="widget-title">
                                    <h3 class="title">Blogs Articles</h3>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>
                                <div class="left-blog-post-widget no-border mb-30">
                                    <div class="post-img">
                                        <a href="#"><img src="assets/images/news/news4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-desc">
                                        <a href="#"> Price revision October 4, 2022 The price of domestically produced
                                            APM gas has been revised by Petroleum Pricing & Analysis Cell </a>
                                        <span class="date-post"> <i class="fa fa-calendar"></i> Aug 8, 2021 </span>
                                    </div>
                                </div>

                                <a href="#" class="bluebtn2 d-block">View All <i
                                        class="demo-icon uil-arrow-right"></i></a>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-9 col-md-9 col-sm-12 order-lg-2 order-md-1 order-1">
                <div class="innerpage-right-content">

                        <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="light-blue-bg">
                           
                                <h6>Registrar and Share Transfer Agent</h6>
                                <p style="line-height: 22px;">MUFG Intime India Private Limited <br>(formerly known as Link Intime India Private Limited) <!--Link Intime India Private Limited--><br>
                                    C-101, 247 Park <br>
                                    L.B.S. Marg, Vikhroli (W)<br>
                                    <strong>Mumbai</strong> - 400 083 <br>
                                    <strong>Tel:</strong> +91 (22) 4918 6000 <br>
                                    <strong>Fax.:</strong> +91 (22) 4918 6060 <br>
                                    <strong>Email:</strong> <a
                                        href="mailto:mgl.ipo@linkintime.co.in">mgl.ipo@linkintime.co.in</a><br>
                                    <strong>Contact Person:</strong> Shanti Gopalkrishnan<br>
                                    <strong>Website:</strong> <a href="http://linkintime.co.in"
                                        target="_blank">www.linkintime.co.in</a>
                                </p>
                        </div> 
                        </div>
                            <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="light-blue-bg">
                            
                                
                                <h6>Company Secretary & Nodal Officer (for the IEPF Authority)</h6>
                                <p style="line-height: 22px;">Mr. Atul Prabhu<br>
                                    MGL House, G-33 Block<br>
                                    Bandra-Kurla Complex, Bandra (East)<br>
                                    <strong>Mumbai</strong> - 400 051<br>
                                    <strong>Tel:</strong> +91 22 6678 5000 <br>
                                    <!--<strong>Fax:</strong> +91 (22) 2652 8925<br />--> <strong>Email:</strong> <a
                                        href="mailto:investorrelations@mahanagargas.com">investorrelations@mahanagargas.com</a>
                                </p>
                            
                        </div>
                        </div>
                        </div>
                        <div class="con mt-30">
                            <h2>Formats for Shareholder Requests</h2>
                            <div *ngFor="let i of allData" class="white-box10 mb-20">
                                <div class="empanelment">
                                    <div class="empanelment-name">
                                        {{i.Title}}
                                    </div>
                                    <a href="{{baseUrl}}{{i.PdfFile}}" target="_Blank" class="bluebtn2 d-block">Download <i
                                            class="demo-icon uil-download-alt"></i></a>
                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>
        </div>
        <!-- Inner Page Content Section End -->
    </div>
</div>    